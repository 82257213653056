import React, { useState } from "react";
import { Container, Typography, Box } from "@mui/material";
import { Form, Field } from "react-final-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomAlert from "../../Components/CustomAlert/CustomAlert";
import CustomPassword from "../../Components/CustomFields/CustomPassword/CustomPassword";
import api from "../../Services/api";
import { Button } from "../Layout/style";
import MainLayout from "../Layout/MainLayout";

const ResetPassword: React.FC = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const navigate = useNavigate();

    const [alert, setAlert] = useState<{ type: "error" | "success"; message: string; open: boolean }>({
        type: "error",
        message: "",
        open: false,
    });

    if (!token) {
        return <CustomAlert type="error" message="Token inválido o expirado." open={true} onClose={() => navigate("/forgot-password")} />;
    }

    const onSubmit = async (values: { newPassword: string; confirmPassword: string }) => {
        setAlert({ ...alert, open: false });

        if (values.newPassword !== values.confirmPassword) {
            setAlert({ type: "error", message: "Las contraseñas no coinciden.", open: true });
            return;
        }

        try {
            const response = await api.post("/api/password/reset", {
                token,
                newPassword: values.newPassword,
            });

            if (response.data.success) {
                setAlert({ type: "success", message: "Contraseña cambiada exitosamente. Redirigiendo...", open: true });
                setTimeout(() => navigate("/login"), 3000);
            } else {
                setAlert({ type: "error", message: "Hubo un problema al restablecer la contraseña.", open: true });
            }
        } catch (error: any) {
            setAlert({
                type: "error",
                message: error.response?.data?.message || "Error al procesar la solicitud.",
                open: true,
            });
        }
    };

    return (
        <MainLayout>

            <Container maxWidth="xs">
                <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                    <img src="/img/giftwave_logo.png" alt="GiftWave Logo" style={{ width: 150, marginBottom: 20 }} />
                    <Typography variant="h5" component="h1" gutterBottom sx={{ fontFamily: "Montserrat", fontWeight: 600 }}>
                        Restablecer Contraseña
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: "Montserrat", fontWeight: 500, color: "#666", textAlign: "center" }}>
                        Ingresa tu nueva contraseña
                    </Typography>

                    {alert.open && <CustomAlert type={alert.type} message={alert.message} open={alert.open} onClose={() => setAlert({ ...alert, open: false })} />}

                    <Form
                        onSubmit={onSubmit}
                        validate={(values) => {
                            const errors: Record<string, string> = {};
                            if (!values.newPassword) errors.newPassword = "Ingrese una nueva contraseña.";
                            if (!values.confirmPassword) errors.confirmPassword = "Confirme la contraseña.";
                            return errors;
                        }}
                        render={({ handleSubmit, submitting }) => (
                            <form onSubmit={handleSubmit} style={{ width: "100%", gap: "16px", display: "flex", flexDirection: "column", marginTop: 20 }}>
                                <Field name="newPassword">
                                    {({ input }) => <CustomPassword {...input} label="Nueva Contraseña" />}
                                </Field>

                                <Field name="confirmPassword">
                                    {({ input }) => <CustomPassword {...input} label="Confirmar Contraseña" />}
                                </Field>

                                <Button type="submit" disabled={submitting}>
                                    {submitting ? "Procesando..." : "Cambiar Contraseña"}
                                </Button>
                            </form>
                        )}
                    />
                </Box>
            </Container>
        </MainLayout>
    );
};

export default ResetPassword;
