import React from 'react';
import {
  AboutContainer,
  Section,
  TeamContainer,
  TeamTitle,
  TeamGrid,
  TeamMemberCard,
  MissionVisionSection,
  MissionVisionQuote,
  ObjectivesSection,
} from './style';
import MainLayout from '../Layout/MainLayout';
import { Link } from 'react-router-dom';
import { Banner, Button, Subtitle, Title } from '../Layout/style';
import { BannerContainer } from '../Register/style';

const About: React.FC = () => {
  return (
    <MainLayout>
      
      <BannerContainer>
        <Banner>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="/img/giftwave_logo.png"
              alt="GiftWave Logo"
              style={{ width: '200px', height: 'auto', marginRight: '20px' }}
            />
            <div>
              <Title>Nuestra Historia</Title>
              <Subtitle>El origen de GiftWave y nuestra pasión por regalar felicidad</Subtitle>
            </div>
          </div>
        </Banner>
      </BannerContainer>

      <AboutContainer>
        {/* Historia de la empresa */}
        <Section>
          <h3>Nuestra Historia</h3>
          <p>
            GiftWave comenzó con una simple idea: hacer que regalar sea más fácil y accesible para todos. Operamos en el sector de los regalos digitales, ofreciendo una solución integral para la adquisición y personalización de tarjetas de regalo. Nuestro enfoque está dirigido tanto a consumidores individuales como a empresas que desean gestionar campañas de regalos para clientes o empleados.
          </p>
          <p>
            Buscamos evolucionar hacia una plataforma integral de experiencias personalizadas, incluyendo paquetes de regalos que combinen productos y servicios únicos, y expandirnos al mercado corporativo y al internacional, adaptándonos a monedas locales y preferencias culturales.
          </p>
        </Section>

        {/* Misión y Visión */}
        <MissionVisionSection>
          <h3>Misión y Visión</h3>
          <MissionVisionQuote>
            "Nuestra misión es proporcionar una solución integral de tarjetas de regalo digitales que combine simplicidad, seguridad y personalización, generando valor tanto para usuarios finales como para empresas, y promoviendo la cultura de regalos emocionales."
          </MissionVisionQuote>
          <p>
            Creemos en la magia de regalar y en la alegría que trae compartir con los demás. Trabajamos día a día para ser la plataforma líder en la gestión de regalos digitales en América Latina, ofreciendo experiencias innovadoras, personalizadas y accesibles.
          </p>
          <MissionVisionQuote>
            "Visión: Ser la plataforma más reconocida de tarjetas de regalo digitales en América Latina, destacándonos por nuestra tecnología avanzada, diseño intuitivo y experiencia de usuario excepcional."
          </MissionVisionQuote>
          <p>
            Nos esforzamos por innovar continuamente, implementando tecnología como la inteligencia artificial para sugerencias personalizadas, y expandiéndonos a nuevos mercados para ofrecer soluciones que conecten emocionalmente a nuestros clientes.
          </p>
        </MissionVisionSection>

        {/* Objetivos */}
        <ObjectivesSection>
          <h3>Objetivos de GiftWave</h3>
          <ul>
            <li>Expandir el acceso a tarjetas de regalo personalizadas para diferentes ocasiones y gustos.</li>
            <li>Ofrecer una experiencia de compra rápida, sencilla y segura.</li>
            <li>Establecer alianzas con empresas locales y fintech para aumentar la oferta y las opciones.</li>
            <li>Implementar algoritmos de inteligencia artificial para mejorar la experiencia del usuario.</li>
          </ul>
        </ObjectivesSection>

        {/* Equipo */}
        <TeamContainer>
          <TeamTitle>Conoce a nuestro Equipo</TeamTitle>
          <TeamGrid>
            <TeamMemberCard>
              <img src="/img/Maicon.jpeg" alt="Maicon de Assis Eliziário" />
              <h4>Maicon de Assis Eliziário</h4>
              <p>CEO & Fundador</p>
            </TeamMemberCard>
            <TeamMemberCard>
              <img src="/img/Juan.png" alt="Juan Ignacio Gonzales" />
              <h4>Juan Ignacio Gonzales</h4>
              <p>Director de Tecnología (CTO)</p>
            </TeamMemberCard>
            <TeamMemberCard>
              <img src="/img/Bruno.jpeg" alt="Bruno Anthony Quiroz" />
              <h4>Bruno Anthony Quiroz</h4>
              <p>Director de Operaciones</p>
            </TeamMemberCard>
          </TeamGrid>
        </TeamContainer>

        {/* Llamada a la acción */}
        <Section>
          <h3>Únete a nuestra misión</h3>
          <p>
            En GiftWave, creemos que regalar es una forma de compartir felicidad. Si compartes nuestra visión y quieres ser parte de este emocionante viaje, no dudes en contactarnos. Siempre estamos en busca de nuevos talentos y colaboradores.
          </p>
          <Link to="/contact">
            <Button>Contáctanos</Button>
          </Link>

        </Section>
      </AboutContainer>
    </MainLayout>
  );
};

export default About;
