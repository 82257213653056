import React, { useCallback, useEffect, useState } from "react";
import api from "../../../Services/api";
import { 
  Container, 
  Title, 
  Table, 
  TableRow, 
  TableCell, 
  TableHeader, 
  AddButton, 
  ActionButton, 
  Image } from "./style";
import GiftCardModal from "./GiftCardModal";
import { Edit, Delete } from "@mui/icons-material";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { GiftCard } from "../../../Interfaces/formInterfaces";

const GiftCards: React.FC = () => {
  const [giftCards, setGiftCards] = useState<GiftCard[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGiftCard, setSelectedGiftCard] = useState<GiftCard | null>(null);
  const navigate = useNavigate();

  const getAuthHeaders = useCallback(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Tu sesión ha expirado. Inicia sesión nuevamente.");
      navigate("/login");
    }
    return { headers: { Authorization: `Bearer ${token}` } };
  }, [navigate]);

  // ✅ Memoizar handleError para evitar re-render innecesario
  const handleError = useCallback((error: unknown, message: string) => {
    if (error instanceof AxiosError) {
      console.error(`${message}:`, error.response?.data || error.message);
      if (error.response?.status === 401 || error.response?.status === 403) {
        alert("No tienes permiso para realizar esta acción.");
        navigate("/");
      }
    } else {
      console.error(`${message}:`, error);
    }
  }, [navigate]);

  // ✅ Ahora `fetchGiftCards` usa handleError memoizado y no causa renders innecesarios.
  const fetchGiftCards = useCallback(async () => {
    try {
      const response = await api.get("api/giftcard", getAuthHeaders());
      setGiftCards(response.data.giftCards);
    } catch (error) {
      handleError(error, "Error obteniendo GiftCards.");
    }
  }, [getAuthHeaders, handleError]);

  useEffect(() => {
    fetchGiftCards();
  }, [fetchGiftCards]);

  const handleDelete = async (id: number) => {
    if (window.confirm("¿Estás seguro de eliminar esta GiftCard?")) {
      try {
        await api.delete(`api/giftcard/${id}`, getAuthHeaders());
        fetchGiftCards();
      } catch (error) {
        handleError(error, "Error eliminando GiftCard.");
      }
    }
  };

  const handleEdit = (giftCard: GiftCard) => {
    setSelectedGiftCard(giftCard);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setSelectedGiftCard(null);
    setIsModalOpen(false);
    fetchGiftCards();
  };

  return (
    <Container>
      <Title>🎁 Administrar GiftCards</Title>
      <AddButton onClick={() => setIsModalOpen(true)}>➕ Agregar GiftCard</AddButton>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>Imagen</TableHeader>
            <TableHeader>Código</TableHeader>
            <TableHeader>Nombre</TableHeader>
            <TableHeader>Tipo</TableHeader>
            <TableHeader>Valor</TableHeader>
            <TableHeader>Stock</TableHeader>
            <TableHeader>Estado</TableHeader>
            <TableHeader>Acciones</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {giftCards.length > 0 ? (
            giftCards.map((giftCard) => (
              <TableRow key={giftCard.id}>
                <TableCell>
                  <Image src={giftCard.imagenUrl || "/img/default.png"} alt={giftCard.nombre} />
                </TableCell>
                <TableCell>{giftCard.codigo}</TableCell>
                <TableCell>{giftCard.nombre}</TableCell>
                <TableCell>{giftCard.tipo}</TableCell>
                <TableCell>${giftCard.valor}</TableCell>
                <TableCell>{giftCard.stock}</TableCell>
                <TableCell>{giftCard.activo ? "🟢 Activo" : "🔴 Inactivo"}</TableCell>
                <TableCell>
                  <ActionButton onClick={() => handleEdit(giftCard)}>
                    <Edit color="primary" />
                  </ActionButton>
                  <ActionButton onClick={() => handleDelete(giftCard.id)}>
                    <Delete color="error" />
                  </ActionButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8} style={{ textAlign: "center" }}>
                No hay GiftCards disponibles.
              </TableCell>
            </TableRow>
          )}
        </tbody>
      </Table>
      {isModalOpen && <GiftCardModal giftCard={selectedGiftCard} onClose={handleModalClose} />}
    </Container>
  );
};

export default GiftCards;
