import React from "react";
import { styled } from "@mui/system";
import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";

const NotificationContainer = styled(Box)<{ type: string }>(({ type }) => ({
  position: "fixed",
  top: "20px",
  right: "20px",
  backgroundColor:
    type === "success"
      ? "#e8f5e9"
      : type === "error"
      ? "#ffebee"
      : type === "warning"
      ? "#fff3e0"
      : "#e3f2fd",
  color:
    type === "success"
      ? "#2e7d32"
      : type === "error"
      ? "#c62828"
      : type === "warning"
      ? "#f57c00"
      : "#0277bd",
  border: `1px solid ${
    type === "success"
      ? "#2e7d32"
      : type === "error"
      ? "#c62828"
      : type === "warning"
      ? "#f57c00"
      : "#0277bd"
  }`,
  padding: "16px",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  gap: "12px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  zIndex: 9999,
  maxWidth: "400px",
}));

const MessageText = styled(Typography)({
  fontSize: "16px",
  fontWeight: "bold",
});

const CloseButton = styled(IconButton)({
  marginLeft: "auto",
  color: "inherit",
});

interface CustomNotificationProps {
  open: boolean;
  type: "success" | "error" | "warning" | "info";
  message: string;
  onClose: () => void;
}

const CustomNotification: React.FC<CustomNotificationProps> = ({
  open,
  type,
  message,
  onClose,
}) => {
  if (!open) return null;

  return (
    <NotificationContainer type={type}>
      {type === "success" && <CheckCircleIcon />}
      {type === "error" && <ErrorIcon />}
      {type === "warning" && <WarningIcon />}
      {type === "info" && <InfoIcon />}
      <MessageText>{message}</MessageText>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
    </NotificationContainer>
  );
};

export default CustomNotification;
