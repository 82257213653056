import React from "react";
import { TextField } from "@mui/material";
import { Field } from "react-final-form";

interface CustomDateProps {
  name: string;
  label: string;
  required?: boolean;
}

const CustomDate: React.FC<CustomDateProps> = ({ name, label, required }) => {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <TextField
          {...input}
          type="date"
          label={label}
          required={required}
          fullWidth
          InputLabelProps={{ shrink: true }}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error ? meta.error : ""}
        />
      )}
    </Field>
  );
};

export default CustomDate;
