import React from "react";
import { useABM } from "../../../Hooks/abmConfig";
import { Cupon } from "../../../Interfaces/backofficeInterfaces";
import { Container } from "../Components/style";
import FormComponent from "../Components/FormComponent/FormComponent";
import TableComponent from "../Components/TableComponent/TableComponent";
import CustomAlert from "../../../Components/CustomAlert/CustomAlert";

const CuponesPage: React.FC = () => {
  const { 
    items, 
    formData, 
    setFormData, 
    handleSave, 
    handleDelete, 
    handleEdit, 
    resetForm, 
    editingId, 
    alert, 
    setAlert, 
    confirmAlert, 
    setConfirmAlert 
  } = useABM<Cupon>({
    endpoint: "api/CuponesDescuento",
    defaultValues: { id: 0, codigo: "", tipoDescuento: "PORCENTAJE", descuento: 0, fechaExpiracion: "" },
  });

  return (
    <Container>
      <CustomAlert 
        open={alert.open} 
        message={alert.message} 
        type={alert.type} 
        onClose={() => setAlert({ ...alert, open: false })} 
      />

      {confirmAlert.open && (
        <CustomAlert 
          open={confirmAlert.open} 
          message={confirmAlert.message} 
          type="warning" 
          onConfirm={confirmAlert.onConfirm} 
          onClose={() => setConfirmAlert({ open: false, message: "" })} 
        />
      )}

      <FormComponent
        formData={formData}
        setFormData={setFormData}
        fields={[
          { name: "codigo", type: "text", placeholder: "Código" },
          {
            name: "tipoDescuento", 
            type: "select", 
            placeholder: "Tipo Descuento", 
            options: [
              { value: "PORCENTAJE", label: "Porcentaje (%)" },
              { value: "VALOR", label: "Valor Fijo ($)" }
            ]
          },
          { name: "descuento", type: "number", placeholder: "Descuento" },
          { name: "fechaExpiracion", type: "date", placeholder: "Fecha Expiración" },
        ]}
        onSubmit={handleSave}
        isEditing={editingId !== null}
        resetForm={resetForm}
      />
      
      <TableComponent
        headers={["ID", "Código", "Tipo", "Descuento", "Fecha Expiración"]}
        data={items}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </Container>
  );
};

export default CuponesPage;
