import styled from 'styled-components';

export const BannerContainer = styled.div`
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 1rem
`;

export const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f0faff; 
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 1000px;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
`;

export const FormGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  gap: 15px; 
  justify-content: space-between;
  width: 100%;
`;

export const Column = styled.div`
  flex: 1;
  min-width: 450px; 
  display: flex;
  flex-direction: column;
  gap: 15px; 
  margin-bottom: 10px;
`;

export const SectionTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px;
  color: #003366;
`;

export const RegisterFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px; /* Espacio entre el botón y el enlace */
    margin-top: 20px;
`;

export const StyledLink = styled.a`
    font-weight: bold;
    text-decoration: none;
    color: #003366;
    transition: color 0.2s ease-in-out;

    &:hover {
        color: #4ca1af;
    }
`;
