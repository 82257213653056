import React from "react";
import { TextField, MenuItem } from "@mui/material";
import { Field } from "react-final-form";

interface CustomSelectProps {
  name: string;
  label: string;
  options: { value: string; label: string }[];
  required?: boolean;
  onChange?: (value: string) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ name, label, options, required, onChange }) => {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <TextField
          {...input}
          select
          label={label}
          fullWidth
          required={required}
          value={input.value || ""}  
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error ? meta.error : ""}
          onChange={(e) => {
            input.onChange(e.target.value);
            if (onChange) onChange(e.target.value);
          }}
        >
          {options.map((option, i) => (
            <MenuItem key={i} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    </Field>
  );
};

export default CustomSelect;
