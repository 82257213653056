import styled from "styled-components";

// 📌 Contenedor principal del perfil
export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f0faff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 1000px;
  margin-left: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;

  @media (max-width: 1024px) {
    margin-left: 0;
    padding: 15px;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    padding: 10px;
  }
`;

// 📌 Título de las secciones
export const SectionTitle = styled.h2`
  font-size: 22px;
  color: #003366;
  font-family: "Roboto", sans-serif;
  width: 100%;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 20px;
  }
`;

// 📌 Grid de datos personales
export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

// 📌 Contenedor de tarjetas de direcciones y contactos
export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  
  .card {
    background: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }

  .card-title {
    font-weight: bold;
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

// 📌 Sidebar del perfil
export const Sidebar = styled.div`
  width: 250px;
  background: rgb(206, 229, 241);
  padding: 15px;
  border-radius: 10px;
  margin-right: 20px;

  @media (max-width: 1024px) {
    width: 200px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    text-align: center;
  }
`;

// 📌 Texto de los elementos del Sidebar
export const SidebarText = styled.span`
  font-size: 18px;
  color: #003366;
  font-family: "Roboto", sans-serif;
  width: 100%;
  margin-left: 10px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

// 📌 Elemento del Sidebar (con estado activo)
export const SidebarItem = styled.div<{ active?: boolean }>`
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  background: ${({ active }) => (active ? "#f0faff" : "transparent")};
  border-radius: 15px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #007bff;
    color: #fff;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

// 📌 Iconos del Sidebar
export const Icon = styled.div`
  color: #ff7e5f;
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;
