import React from "react";
import { TextField } from "@mui/material";
import { Field } from "react-final-form";

interface CustomTextProps {
  name: string;
  label: string;
  required?: boolean;
  value?: string; 
  disabled?: boolean;
}

const CustomText: React.FC<CustomTextProps> = ({ name, label, required, disabled, value }) => {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <TextField
          {...input}
          type="text"
          label={label}
          required={required}
          fullWidth
          disabled={disabled}
          value={disabled ? value || "" : input.value}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error ? meta.error : ""}
          onChange={(e) => {
            const value = e.target.value;
            input.onChange(value);
          }}
        />
      )}
    </Field>
  );
};

export default CustomText;
