import styled from 'styled-components';

export const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
`;

export const Logo = styled.img`
    padding-bottom: 20px;
    width: 150px;
`;

export const Title = styled.div`
    font-size: 1.5rem;
    color: #003366;
    text-align: center;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 10px;
`;

export const Subtitle = styled.div`
    font-size: 1rem;
    color: #003366;
    text-align: center;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 10px;
`;

export const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 400px;
    gap: 20px;
`;

export const Input = styled.input`
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #d0e7ff;
    background: #ffffff;
    color: #333333;
`;

export const LinkText = styled.p`
    font-size: 0.9rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #003366;
    cursor: pointer;
    text-align: center;
    margin: 2px;

    span, a {
        font-weight: bold;
        text-decoration: none;
        color: #003366;
        transition: color 0.2s ease-in-out;
    }

    span:hover, a:hover {
        color: #ff7e5f;
    }
`;

