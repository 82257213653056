import React, { createContext, useContext, useEffect, useState } from "react";
import { CartItem } from "../../Interfaces/cartItem";
import api from "../../Services/api";
import CustomAlert from "../../Components/CustomAlert/CustomAlert"; 

interface CartContextProps {
  cartItems: CartItem[];
  addToCart: (item: CartItem) => void;
  removeCartItem: (id: number) => void;
  updateCartItem: (id: number, quantity: number) => void;
  applyDiscount: (code: string) => void;
  validateDiscount: () => Promise<void>;
  discountCode: string;
  discountAmount: number;
  setDiscountCode: (code: string) => void;
  setDiscountAmount: (amount: number) => void;
  total: number;
  methodPaymentId: number | null;
  setMethodPayment: (id: number) => void;
  checkout: () => Promise<void>;
  isCartOpen: boolean;
  setIsDiscountApplied: (state: boolean) => void;
  openCart: () => void;
  closeCart: () => void;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [cartItems, setCartItems] = useState<CartItem[]>(() => {
    const storedCart = localStorage.getItem("cartItems");
    return storedCart ? JSON.parse(storedCart) : [];
  });

  const [discountCode, setDiscountCode] = useState<string>("");
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [methodPaymentId, setMethodPayment] = useState<number | null>(null);
  const [isCartOpen, setIsCartOpen] = useState<boolean>(false);
  const [isDiscountApplied, setIsDiscountApplied] = useState<boolean>(false);
  const [alert, setAlert] = useState<{ open: boolean; message: string; type: "success" | "error" | "warning" }>({
    open: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const total = cartItems.reduce((acc, item) => acc + item.valor * item.quantity, 0) - discountAmount;

  const openCart = () => setIsCartOpen(true);
  const closeCart = () => setIsCartOpen(false);

  const addToCart = (item: CartItem) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find((cartItem) => cartItem.id === item.id);
      return existingItem
        ? prevItems.map((cartItem) =>
            cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
          )
        : [...prevItems, { ...item, quantity: 1 }];
    });

    openCart();
  };

  const updateCartItem = (id: number, quantity: number) => {
    setCartItems((prevItems) =>
      quantity > 0
        ? prevItems.map((item) => (item.id === id ? { ...item, quantity } : item))
        : prevItems.filter((item) => item.id !== id)
    );
  };

  const removeCartItem = (id: number) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const applyDiscount = (code: string) => {
    setDiscountCode(code);
  };

  const validateDiscount = async () => {
    if (!discountCode.trim()) {
      setAlert({ open: true, message: "Ingrese un código de descuento.", type: "warning" });
      return;
    }

    if (isDiscountApplied) {
      setAlert({ open: true, message: "Ya se ha aplicado un cupón de descuento.", type: "error" });
      return;
    }

    try {
      const response = await api.get(`/api/CuponesDescuento/${discountCode}`);
      const cupon = response.data;

      if (!cupon || !cupon.estado) {
        throw new Error("Cupón inválido o expirado.");
      }

      const descuento =
        cupon.tipoDescuento === "PORCENTAJE" ? total * (cupon.descuento / 100) : cupon.descuento;

      setDiscountAmount(descuento);
      setIsDiscountApplied(true);
      setAlert({ open: true, message: "Descuento aplicado con éxito.", type: "success" });
    } catch (error) {
      setDiscountAmount(0);
      setAlert({ open: true, message: "Cupón inválido o expirado.", type: "error" });
    }
  };

  const checkout = async () => {
    if (cartItems.length === 0) {
        setAlert({ open: true, message: "El carrito está vacío.", type: "warning" });
        return null; // ✅ Retorna null si falla
    }

    if (!methodPaymentId) {
        setAlert({ open: true, message: "Selecciona un método de pago.", type: "warning" });
        return null; // ✅ Retorna null si falla
    }

    const token = localStorage.getItem("token");
    if (!token) {
        setAlert({ open: true, message: "No estás autenticado. Por favor, inicia sesión.", type: "error" });
        return null; // ✅ Retorna null si falla
    }

    try {
        const venta = {
            usuarioId: 1,
            detalles: cartItems.map((item) => ({
                giftCardId: item.id,
                cantidad: item.quantity,
            })),
            metodoPagoId: methodPaymentId,
            codigoCupon: discountCode || null,
        };

        const response = await api.post("/api/venta", venta, {
            headers: { Authorization: `Bearer ${token}` },
        });

        console.log("🔹 Venta realizada, response:", response.data); 

        setAlert({ open: true, message: `Compra realizada con éxito. Número de compra: ${response.data.numeroComprobante}`, type: "success" });

        // ✅ Retorna los datos de la venta en caso de éxito
        return response.data; 
    } catch (error) {
        console.error("❌ Error al procesar la compra:", error);
        setAlert({ open: true, message: "Error al procesar la compra.", type: "error" });
        return null; // ✅ Retorna null si falla
    }
};

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeCartItem,
        updateCartItem,
        applyDiscount,
        validateDiscount,
        discountCode,
        discountAmount,
        total,
        methodPaymentId,
        setMethodPayment,
        checkout,
        isCartOpen,
        openCart,
        closeCart,
        setDiscountCode,
        setDiscountAmount,
        setIsDiscountApplied,
      }}
    >
      {children}
      <CustomAlert open={alert.open} message={alert.message} type={alert.type} onClose={() => setAlert({ ...alert, open: false })} />
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) throw new Error("useCart debe usarse dentro de un CartProvider");
  return context;
};
