import React, { useState } from 'react';
import api from '../../Services/api';
import { Form } from 'react-final-form';
import MainLayout from '../Layout/MainLayout';
import { decodeToken } from '../../Hooks/useAuth';
import CustomEmail from '../../Components/CustomFields/CustomEmail/CustomEmail';
import CustomPassword from '../../Components/CustomFields/CustomPassword/CustomPassword';
import CustomAlert from '../../Components/CustomAlert/CustomAlert';
import { LinkText, LoginContainer, LoginForm, Logo, Subtitle, Title } from './style';
import { Button } from '../Layout/style';

const Login: React.FC = () => {
    const [error, setError] = useState('');
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const [alert, setAlert] = useState<{ type: "error" | "success"; message: string; open: boolean } >({
        type: "error",
        message: "",
        open: false,
    });

    // ✅ Manejo de Login
    const handleLogin = async (values: { usuario?: string; password?: string; email?: string }) => {
        setError('');
    
        if (isForgotPassword) {
            await handleForgotPassword(values);
        } else {
            try {
                const response = await api.post('/api/auth/login', {
                    usuario: values.usuario,
                    password: values.password,
                });
    
                if (response.status === 200) {
                    const { token } = response.data;
                    localStorage.setItem('token', token);
    
                    const decodedToken = decodeToken(token);
    
                    const redirectURL = localStorage.getItem("redirectAfterLogin") || "/";
                    localStorage.removeItem("redirectAfterLogin");
    
                    window.location.href = decodedToken?.TipoUsuario === "Administrador"
                        ? "/backoffice/dashboard"
                        : redirectURL;
                }
            } catch (error) {
                setError('Email o contraseña incorrectos');
            }
        }
    };
    
    // ✅ Manejo de Recuperación de Contraseña
    const handleForgotPassword = async (values: { email?: string }) => {
        setAlert({ ...alert, open: false });

        if (!values.email) {
            setAlert({ type: "error", message: "Ingrese su email.", open: true });
            return;
        }

        try {
            const response = await api.post('/api/password/forgot', { email: values.email });

            if (response.data.success) {
                setAlert({
                    type: "success",
                    message: "Se ha enviado un correo con las instrucciones para restablecer la contraseña.",
                    open: true,
                });
            } else {
                setAlert({ type: "error", message: "No se pudo enviar el correo.", open: true });
            }
        } catch (error: any) {
            setAlert({
                type: "error",
                message: error.response?.data?.message || "Error al procesar la solicitud.",
                open: true,
            });
        }
    };

    return (
        <MainLayout>
            <LoginContainer>
                <Logo src="/img/giftwave_logo.png" alt="GiftWave Logo" />
                <Title>{isForgotPassword ? "Recuperar Contraseña" : "Bienvenido"}</Title>
                <Subtitle>{isForgotPassword ? "Ingresa tu email para recibir el enlace de recuperación." : "Iniciar sesión"}</Subtitle>

                {alert.open && <CustomAlert type={alert.type} message={alert.message} open={alert.open} onClose={() => setAlert({ ...alert, open: false })} />}

                <Form
                    onSubmit={handleLogin}
                    render={({ handleSubmit }) => (
                        <LoginForm onSubmit={handleSubmit}>
                            {isForgotPassword ? (
                                <CustomEmail name="email" label="Email" />
                            ) : (
                                <>
                                    <CustomEmail name="usuario" label="Email" />
                                    <CustomPassword name="password" label="Contraseña" />
                                    {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
                                </>
                            )}
                            <Button align="right" type="submit">{isForgotPassword ? "Enviar Enlace" : "Entrar"}</Button>
                        </LoginForm>
                    )}
                />

                {!isForgotPassword ? (
                    <>
                        <LinkText onClick={() => setIsForgotPassword(true)}>
                            ¿Olvidaste la contraseña? <span>Recuperar</span>
                        </LinkText>
                        <LinkText>
                            ¿Aún no estás registrado? <a href="/register">Regístrate</a>
                        </LinkText>
                    </>
                ) : (
                    <LinkText onClick={() => setIsForgotPassword(false)}>
                       <span>Volver al inicio de sesión</span> 
                    </LinkText>
                )}
            </LoginContainer>
        </MainLayout>
    );
};

export default Login;
