import React, { useState } from "react";
import api from "../../../Services/api";
import { ModalContainer, ModalContent, Input, Select, CloseButton } from "./styleModal";
import { GiftCard } from "../../../Interfaces/formInterfaces";
import { Button } from "../../Layout/style";

interface GiftCardModalProps {
  giftCard?: GiftCard | null;
  onClose: () => void;
}

const GiftCardModal: React.FC<GiftCardModalProps> = ({ giftCard, onClose }) => {
  const [nombre, setNombre] = useState(giftCard?.nombre || "");
  const [valor, setValor] = useState(giftCard?.valor || 0);
  const [tipo, setTipo] = useState(giftCard?.tipo || "Física");
  const [stock, setStock] = useState(giftCard?.stock || 0);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const data = { nombre, valor, tipo, stock };

    try {
      if (giftCard) {
        await api.put(`api/giftcard/${giftCard.id}`, data);
      } else {
        await api.post("api/giftcard", data);
      }
      onClose();
    } catch (error) {
      console.error("Error al guardar GiftCard:", error);
    }
  };

  return (
    <ModalContainer>
      <ModalContent>
        <h2>{giftCard ? "Editar GiftCard" : "Agregar GiftCard"}</h2>
        <form onSubmit={handleSubmit}>
          <Input type="text" placeholder="Nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
          <Input type="number" placeholder="Valor" value={valor} onChange={(e) => setValor(Number(e.target.value))} required />
          <Select value={tipo} onChange={(e) => setTipo(e.target.value)}>
            <option value="Física">Física</option>
            <option value="Digital">Digital</option>
          </Select>
          <Input type="number" placeholder="Stock" value={stock} onChange={(e) => setStock(Number(e.target.value))} required />
          <Button type="submit">{giftCard ? "Actualizar" : "Agregar"}</Button>
          <CloseButton onClick={onClose}>Cancelar</CloseButton>
        </form>
      </ModalContent>
    </ModalContainer>
  );
};

export default GiftCardModal;
