import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar, SidebarItem, SidebarText } from "./style";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";   
import EditIcon from "@mui/icons-material/Edit";                 
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";  
import PersonIcon from "@mui/icons-material/Person";             
import { Divider } from "@mui/material";

const UserSidebar: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Sidebar>
            <Divider/>

            <SidebarItem onClick={() => navigate("/profile")} active={location.pathname === "/profile"}>
                <PersonIcon  style={{ color: "#ff7e5f" }} />
                <SidebarText>Mis Datos</SidebarText>
            </SidebarItem>
            <Divider />

            <SidebarItem onClick={() => navigate("/profileEdit")} active={location.pathname === "/profileEdit"}>
                <EditIcon  style={{ color: "#ff7e5f" }} />
                <SidebarText>Editar Datos</SidebarText>
            </SidebarItem>
            <Divider/>

            <SidebarItem onClick={() => navigate("/miscompras")} active={location.pathname === "/miscompras"}>
                <ShoppingBagIcon  style={{ color: "#ff7e5f" }} />
                <SidebarText>Mis Compras</SidebarText>
            </SidebarItem>
            <Divider />

            <SidebarItem onClick={() => navigate("/facturas")} active={location.pathname === "/facturas"}>
                <ReceiptLongIcon  style={{ color: "#ff7e5f" }} />
                <SidebarText>Mis Facturas</SidebarText>
            </SidebarItem>
            <Divider />

        </Sidebar>
    );
};

export default UserSidebar;
