import { Field } from "react-final-form";
import { SectionTitle } from "../Register/style";
import { PaymentButton } from "./style";
import CustomNumber from "../../Components/CustomFields/CustomNumber/CustomNumber";
import CustomText from "../../Components/CustomFields/CustomText/CustomText";
import CustomDate from "../../Components/CustomFields/CustomDate/CustomDate";

export const CreditCardForm = () => (
    <div>
        <SectionTitle>Detalles de la Tarjeta</SectionTitle>

        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Field name="numeroTarjeta">
                {({ input }) => (
                    <CustomNumber {...input} label="Número de Tarjeta" />
                )}
            </Field>

            <Field name="nombreTitular">
                {({ input }) => (
                    <CustomText {...input} label="Nombre del Titular" />
                )}
            </Field>

            <Field name="dniTitular">
                {({ input }) => (
                    <CustomNumber {...input} label="DNI del Titular" />
                )}
            </Field>

            <div style={{ display: "flex", gap: "15px" }}>
                <Field name="vencimiento">
                    {({ input }) => (
                        <CustomDate {...input} label="Vencimiento" />
                    )}
                </Field>

                <Field name="cvv">
                    {({ input }) => (
                        <CustomNumber {...input} label="CVV" />
                    )}
                </Field>
            </div>
        </div>
    </div>
);

export const TransferenciaForm = () => (
    <div>
        <SectionTitle>Detalles para Transferencia Bancaria</SectionTitle>
        <p>
            Para completar tu compra, realiza una transferencia bancaria a la siguiente cuenta:
        </p>
        <ul>
            <li><strong>Banco:</strong> Banco Ejemplo</li>
            <li><strong>Titular:</strong> Juan Pérez</li>
            <li><strong>CBU:</strong> 1234567890123456789012</li>
            <li><strong>Alias:</strong> mi-tienda.mp</li>
            <li><strong>CUIT:</strong> 20-12345678-9</li>
        </ul>
        <p>
            Una vez realizada la transferencia, envía el comprobante a 
            <strong> pagos@mi-tienda.com</strong>.
        </p>
    </div>
);

export const EfectivoForm = () => (
    <div>
        <SectionTitle>Pago en Efectivo</SectionTitle>
        <p>
            Puedes pagar en efectivo en cualquier sucursal de RapiPago o PagoFácil. 
            Una vez que confirmes tu compra, recibirás un código de pago que deberás 
            presentar en la sucursal más cercana.
        </p>
        <p>
            Tienes <strong>48 horas</strong> para realizar el pago antes de que la orden sea cancelada.
        </p>
    </div>
);

const handleMercadoPago = () => {
    // Simulación de URL de pago (esto debería ser dinámico desde tu backend)
    const urlPago = "https://www.mercadopago.com.ar/checkout/v2/";
    window.location.href = urlPago;
};

export const MercadoPagoForm = () => (
    <div>
        <SectionTitle>Pago con MercadoPago</SectionTitle>
        <p>Serás redirigido a MercadoPago para completar tu compra.</p>
        <PaymentButton onClick={handleMercadoPago}>
            Pagar con MercadoPago
        </PaymentButton>
    </div>
);