import styled from "styled-components";

// Contenedor del modal
export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// Contenido del modal
export const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 500px;
  text-align: center;

  h2 {
    margin-bottom: 1.5rem;
    color: #003366;
  }
`;

// Input estilizado
export const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: #003366;
    box-shadow: 0 0 5px rgba(0, 51, 102, 0.5);
  }
`;

// Select estilizado
export const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  background: white;

  &:focus {
    outline: none;
    border-color: #003366;
    box-shadow: 0 0 5px rgba(0, 51, 102, 0.5);
  }
`;

// Botón de formulario
export const FormButton = styled.button`
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  border: none;
  padding: 12px;
  font-size: 1.1rem;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  transition: all 0.3s;

  &:hover {
    background: linear-gradient(135deg, #0056b3, #004494);
  }
`;

// Botón de cerrar modal
export const CloseButton = styled.button`
  background: #d9534f;
  color: white;
  border: none;
  padding: 10px;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  transition: all 0.3s;

  &:hover {
    background: #c9302c;
  }
`;
