import React from "react";
import { DialogContent, Modal, Typography } from "@mui/material";
import { CustomAlertContainer, AlertIcon, CloseButton, AlertButtons, ConfirmButton, CancelButton } from "./style";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CloseIcon from "@mui/icons-material/Close";

type AlertType = "success" | "error" | "warning";

interface CustomAlertProps {
  open: boolean;
  message: string;
  type?: AlertType;
  onClose: () => void;
  onConfirm?: () => void; 
}

const CustomAlert: React.FC<CustomAlertProps> = ({ open, message, type = "success", onClose, onConfirm }) => {
  const renderIcon = () => {
    switch (type) {
      case "success":
        return <CheckCircleIcon />;
      case "error":
        return <ErrorIcon />;
      case "warning":
        return <WarningAmberIcon />;
      default:
        return null;
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <DialogContent>
        <CustomAlertContainer type={type}>
          <AlertIcon type={type}>{renderIcon()}</AlertIcon>
          <div className="alert-content">
            <Typography variant="h6" className="alert-title">
              {type === "success" ? "¡Éxito!" : type === "error" ? "¡Error!" : "¡Atención!"}
            </Typography>
            <Typography className="alert-message">{message}</Typography>

            {type === "warning" && onConfirm ? (
              <AlertButtons>
                <ConfirmButton onClick={onConfirm}>Sí</ConfirmButton>
                <CancelButton onClick={onClose}>No</CancelButton>
              </AlertButtons>
            ) : (
              <CloseButton onClick={onClose}>
                <CloseIcon />
              </CloseButton>
            )}
          </div>
        </CustomAlertContainer>
      </DialogContent>
    </Modal>
  );
};

export default CustomAlert;
