import React from "react";
import { Modal, Box, Typography, Divider } from "@mui/material";
import { ModalContainer, ModalContent, CloseButton, Header, CanjeCode, EstadoBadge } from "./style";
import { Compra } from "../../Interfaces/compra";
import CloseIcon from "@mui/icons-material/Close";
import { SectionTitle } from "../Profile/style";
import { Button } from "../Layout/style";

interface Props {
  compra: Compra;
  onClose: () => void;
}

const CompraDetalleModal: React.FC<Props> = ({ compra, onClose }) => {
  return (
    <Modal open={true} onClose={onClose}>
      <ModalContainer>
        {/* Cabecera con botón de cierre */}
        <Header>
          <SectionTitle>Compra detalles - N.º {compra.numeroComprobante}</SectionTitle>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </Header>

        {/* Contenido del modal con mejor scroll */}
        <ModalContent>
          <Divider sx={{ marginBottom: 2 }} />

          <Typography variant="subtitle1"><strong>Fecha:</strong> {new Date(compra.fechaVenta).toLocaleDateString()}</Typography>
          <Typography variant="subtitle1"><strong>Subtotal:</strong> ${compra.subTotal ? compra.subTotal.toFixed(2) : "0.00"}</Typography>

          {compra.cuponAplicado && (
            <Typography variant="subtitle1">
              <strong>Cupón aplicado:</strong> {compra.cuponAplicado.codigo} - Descuento: ${compra.cuponAplicado.descuentoAplicado ? compra.cuponAplicado.descuentoAplicado.toFixed(2) : "0.00"}
            </Typography>
          )}

          <Typography variant="subtitle1"><strong>Total:</strong> ${compra.total ? compra.total.toFixed(2) : "0.00"}</Typography>
          <Typography variant="subtitle1"><strong>Estado:</strong> <EstadoBadge estado={compra.estado}>{compra.estado}</EstadoBadge></Typography>

          <Typography variant="subtitle1">
            <strong>Método de Pago:</strong> {compra.pago ? compra.pago.metodoPago.nombre : "No registrado"}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Monto Pagado:</strong> {compra.pago ? `$${compra.pago.montoPagado.toFixed(2)}` : "No disponible"}
          </Typography>

          {compra.factura && (
            <>
              <Divider sx={{ marginY: 2 }} />
              <SectionTitle>Factura</SectionTitle>
              <Button>Descargar factura</Button>
            </>
          )}

          <Divider sx={{ marginY: 2 }} />
          <SectionTitle>Detalles de Gift Cards</SectionTitle>

          {compra.detalles.map((detalle) => (
            <Box key={detalle.giftCardId} mt={2} padding="10px" borderRadius="8px" bgcolor="#f9f9f9">
              <SectionTitle>{detalle.nombre}</SectionTitle>
              <Typography><strong>Precio Unitario:</strong> ${detalle.precioUnitario ? detalle.precioUnitario.toFixed(2) : "0.00"}</Typography>
              <Typography><strong>Cantidad:</strong> {detalle.cantidad}</Typography>
              <Typography><strong>Subtotal:</strong> ${(detalle.precioUnitario * detalle.cantidad).toFixed(2)}</Typography>
              <Typography><strong>Descripción:</strong> {detalle.detalles}</Typography>
              <Typography><strong>Condiciones:</strong> {detalle.condiciones}</Typography>

              {detalle.codigosCanje.length > 0 && (
                <>
                  <Divider sx={{ marginY: 2 }} />
                  <Typography variant="subtitle1">Códigos de Canje:</Typography>
                  {detalle.codigosCanje.map((canje) => (
                    <CanjeCode key={canje.id}>
                      <div className="code-header">
                        <Typography className="code"><strong>{canje.codigo}</strong></Typography>
                        <Typography className="expiry">Vence el {new Date(canje.fechaExpiracion).toLocaleDateString()}</Typography>
                      </div>
                      <div className="availability">
                        <Typography className="status-text">
                          {canje.disponible ? "Disponible ✅" : "Ya canjeado o expirado ❌"}
                        </Typography>
                      </div>
                    </CanjeCode>
                  ))}
                </>
              )}
            </Box>
          ))}
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
};

export default CompraDetalleModal;
