import styled, { keyframes } from "styled-components";
import { Drawer, ListItemButton, ListItemText } from "@mui/material";

// Animación de Fade-In
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const drawerWidth = 270;

/* 🔹 Contenedor General */
export const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: #f0f5ff;
  font-family: "Montserrat", sans-serif;
`;

/* 🔹 Sidebar */
export const Sidebar = styled(Drawer).attrs({
  variant: "permanent",
  sx: {
    width: drawerWidth,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: drawerWidth,
      boxSizing: "border-box",
      background: "linear-gradient(135deg, #b3e5fc, #0056b3)",
      color: "white",
    },
  },
})``;

/* 🔹 Contenedor del Logo */
export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const Logo = styled.img`
  width: 150px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

export const SidebarTitle = styled.h2`
  font-size: 1.5rem;
  color: white;
  margin-top: 10px;
  font-weight: bold;
`;

/* 🔹 Botón de Sidebar */
export const SidebarItemButton = styled(ListItemButton)`
  padding: 12px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  color: white;

  &:hover {
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
  }
`;

export const SidebarText = styled(ListItemText)`
  span {
    font-size: 1rem;
    font-weight: 500;
    margin-left: 20px
  }
`;

/* 🔹 Submenú */
export const SubmenuContainer = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding-left: 20px;
`;

export const SubmenuItem = styled(ListItemButton)`
  padding: 10px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  border-radius: 5px;
  color: #ffffffb3;

  &:hover {
    background: #007bff;
    color: white;
  }
`;

/* 🔹 Botón de Logout */
export const LogoutButton = styled.button`
  background: linear-gradient(135deg, #d9534f, #c9302c);
  color: white;
  border: none;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px auto;
  justify-content: center;
  width: 90%;

  &:hover {
    background: #c9302c;
  }
`;

/* 🔹 Contenido Principal */
export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 20px;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

/* 🔹 Header */
export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 10px 20px;
  box-shadow: 10px 14px 18px rgba(0, 0, 0, 0.1);
`;

export const TitlePage = styled.h1`
  font-size: 1.8rem;
  font-weight: bold;
  color: #003366;
  text-shadow: 5px 5px 2px rgba(0, 0, 0, 0.1);
`;

/* 🔹 Contenedor principal del ABM */
export const Container = styled.div`
  padding: 20px;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 1200px;
  margin: 0 auto;
`;

/* 🔹 Título de la sección */
export const Title = styled.h2`
  font-size: 1.8rem;
  color: #003366;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
`;

/* 🔹 Formulario */
export const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background: #f0f5ff;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
`;

/* 🔹 Campos de entrada */
export const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  flex: 1;
  min-width: 200px;
  transition: 0.3s;

  &:focus {
    border-color: #0056b3;
    outline: none;
    box-shadow: 0px 0px 5px rgba(0, 86, 179, 0.3);
  }
`;

/* 🔹 Select */
export const Select = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  min-width: 200px;
  transition: 0.3s;

  &:focus {
    border-color: #0056b3;
    outline: none;
    box-shadow: 0px 0px 5px rgba(0, 86, 179, 0.3);
  }
`;

/* 🔹 Botón del formulario */
export const FormButton = styled.button`
  background: #28a745;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  min-width: 120px;

  &:hover {
    background: #218838;
  }

  &.delete {
    background: #dc3545;

    &:hover {
      background: #c82333;
    }
  }
`;

/* 🔹 Tabla */
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
`;

/* 🔹 Filas */
export const TableRow = styled.tr`
  &:nth-child(even) {
    background: #f9f9f9;
  }
`;

/* 🔹 Encabezado */
export const TableHeader = styled.th`
  background: #0056b3;
  color: white;
  padding: 12px;
  text-align: left;
`;

/* 🔹 Celdas */
export const TableCell = styled.td`
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
`;
