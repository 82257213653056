import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";

const getModalStyles = (type: "success" | "error" | "warning") => {
  switch (type) {
    case "success":
      return { bgColor: "#E9F7EF", borderColor: "#28a745", textColor: "#155724", icon: <CheckCircleIcon fontSize="large" /> };
    case "error":
      return { bgColor: "#F8D7DA", borderColor: "#dc3545", textColor: "#721c24", icon: <ErrorIcon fontSize="large" /> };
    case "warning":
      return { bgColor: "#FFF3CD", borderColor: "#ffc107", textColor: "#856404", icon: <WarningIcon fontSize="large" /> };
    default:
      return { bgColor: "#E9F7EF", borderColor: "#28a745", textColor: "#155724", icon: <CheckCircleIcon fontSize="large" /> };
  }
};

const ModalContainer = styled(Box)<{ type: "success" | "error" | "warning" }>(({ type }) => {
  const { bgColor, borderColor } = getModalStyles(type);
  return {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: bgColor,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px",
    padding: "20px",
    width: "450px",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    border: `2px solid ${borderColor}`,
  };
});

const ModalIcon = styled(Box)(({ type }: { type: "success" | "error" | "warning" }) => {
  const { textColor } = getModalStyles(type);
  return {
    color: textColor,
    marginRight: "15px",
  };
});

const ModalContent = styled(Box)({
  flex: 1,
});

const ModalTitle = styled(Typography)(({ type }: { type: "success" | "error" | "warning" }) => {
  const { textColor } = getModalStyles(type);
  return {
    fontSize: "18px",
    fontWeight: "bold",
    color: textColor,
    marginBottom: "5px",
  };
});

const ModalMessage = styled(Typography)(({ type }: { type: "success" | "error" | "warning" }) => {
  const { textColor } = getModalStyles(type);
  return {
    fontSize: "14px",
    color: textColor,
  };
});

const CloseButton = styled(IconButton)({
  position: "absolute",
  top: "10px",
  right: "10px",
});

interface CustomModalProps {
  open: boolean;
  type: "success" | "error" | "warning";
  title: string;
  messages: string[];
  onClose: () => void;
}

const CustomModal: React.FC<CustomModalProps> = ({ open, type, title, messages, onClose }) => {
  const { icon } = getModalStyles(type);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer type={type}>
        <ModalIcon type={type}>{icon}</ModalIcon>
        <ModalContent>
          <ModalTitle type={type}>{title}</ModalTitle>
          {messages.map((msg, index) => (
            <ModalMessage key={index} type={type}>{msg}</ModalMessage>
          ))}
        </ModalContent>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </ModalContainer>
    </Modal>
  );
};

export default CustomModal;
