import styled from "styled-components";

export const DashboardContainer = styled.div`
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    font-family: "Arial", sans-serif;
    background: #f8f9fa;
`;

export const Section = styled.div`
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    background: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const CardGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
`;

export const CardItem = styled.div`
    background: linear-gradient(to right, #4A90E2, #50E3C2);
    color: white;
    padding: 20px;
    border-radius: 8px;
    flex: 1;
    min-width: 200px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: scale(1.05);
    }
`;

export const CardItens = styled.div<{ bgColor?: string }>`
    background: ${({ bgColor }) => bgColor || "#ffffff"};
    color: white;
    padding: 20px;
    border-radius: 8px;
    flex: 1;
    min-width: 150px;
    text-align: center;
    flex-direction: column;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: scale(1.05);
    }
`;

export const RefreshButton = styled.button`
    padding: 12px 20px;
    border: none;
    background: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    transition: background 0.3s ease-in-out;

    &:hover {
        background: #0056b3;
    }
`;

export const ChartContainer = styled.div`
    margin: 20px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
`;

export const ChartTitle = styled.h3`
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
`;

export const StyledCard = styled.div`
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;

    &:hover {
        transform: translateY(-5px);
    }
`;

export const StyledTitle = styled.h2`
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
`;

export const getMetodoPagoColor = (metodoPagoId: number) => {
    const colors: { [key: number]: string } = {
        1: "#FF6384",  // Tarjeta de Crédito
        2: "#36A2EB",  // Tarjeta de Débito
        3: "#FFCE56",  // Transferencia Bancaria
        4: "#4BC0C0",  // MercadoPago
        5: "#9966FF",  // Efectivo
    };
    return colors[metodoPagoId] || "#CCCCCC"; // Color por defecto si no está en la lista
};

export const LogContainer = styled.div`
    background: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
`;

export const LogItem = styled.p`
    font-size: 14px;
    color: #333;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;

    &:last-child {
        border-bottom: none;
    }
`;


