import { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import { useCart } from "../../Components/CartContext/CartContext";
import MainLayout from "../Layout/MainLayout";
import api from "../../Services/api";
import {
    CheckoutContainer,
    LeftSection,
    SummaryList,
    SummaryItem,
    Miniatura,
    PaymentButton,
    AddressField,
    EditUserButton,
    QuantityButton,
    RemoveItemButton,
    PaymentMethodIconContainer,
    PaymentMethodLabel
} from "./style";
import { BannerContainer } from "../Register/style";
import { Banner, Subtitle, Title } from "../Layout/style";
import { SectionTitle } from "../Profile/style";
import { Section } from "../About/style";
import CustomAlert from "../../Components/CustomAlert/CustomAlert";
import { Subtotal } from "../../Components/ShoppingCart/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { CreditCardForm, EfectivoForm, MercadoPagoForm, TransferenciaForm } from "./HacerPago";
import { PaymentMethodContainer } from "../../Components/CustomPaymentMethod/style";

const Checkout = () => {
    const { cartItems, discountCode, discountAmount, total, setMethodPayment, checkout, updateCartItem, removeCartItem } = useCart();
    const { isAuthenticated, loading } = useAuth();
    const navigate = useNavigate();

    const [user, setUser] = useState<any>(null);
    const [loadingUser, setLoadingUser] = useState(true);
    const [alert, setAlert] = useState<{ open: boolean; message: string; type: "success" | "error" | "warning" }>({
        open: false,
        message: "",
        type: "success",
    });

    const [metodosPago, setMetodosPago] = useState<{ id: number; nombre: string; iconUrls: string[] }[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loadingMetodosPago, setLoadingMetodosPago] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (!loading && !isAuthenticated) {
            localStorage.setItem("redirectAfterLogin", "/checkout");
            navigate("/login");
        } else {
            const fetchUserData = async () => {
                try {
                    const response = await api.get("/api/usuario/me", {
                        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                    });
                    setUser(response.data);
                } catch (error) {
                    setAlert({ open: true, message: "Error cargando el usuario.", type: "error" });
                } finally {
                    setLoadingUser(false);
                }
            };
            fetchUserData();
        }
    }, [isAuthenticated, loading, navigate]);

    const formatPhoneNumber = (contacto: any) => {
        if (!contacto) return "No disponible";
        return `(+${contacto.codigoPais}) ${contacto.codigoArea} ${contacto.numero.slice(0, 4)}-${contacto.numero.slice(4)}`;
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await api.get("/api/usuario/me", {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                });
                setUser(response.data);
            } catch (error) {
                setAlert({ open: true, message: "Error cargando el usuario.", type: "error" });
            } finally {
                setLoadingUser(false);
            }
        };

        const fetchMetodosPago = async () => {
            try {
                const response = await api.get("/api/config/metodosPago", {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                });

                const metodosConImagen = response.data.map((m: any) => ({
                    id: m.id,
                    nombre: m.nombre,
                    iconUrls: m.nombre.includes("Crédito")
                        ? ["/iconos/visa-credito.png", "/iconos/master-credito.png", "/iconos/amex-credito.png", "/iconos/naranja-credito.png"]
                        : m.nombre.includes("Débito")
                            ? ["/iconos/visa-debito.png", "/iconos/master-debito.png", "/iconos/naranja-debito.png"]
                            : m.nombre.includes("MercadoPago")
                                ? ["/iconos/mercado.png"]
                                : m.nombre.includes("Transferencia")
                                    ? ["/iconos/transfer.png"]
                                    : m.nombre.includes("Efectivo")
                                        ? ["/iconos/efectivo.png"]
                                        : []
                }));

                setMetodosPago(metodosConImagen);
            } catch (error) {
                setAlert({ open: true, message: "Error obteniendo métodos de pago.", type: "error" });
            } finally {
                setLoadingMetodosPago(false);
            }
        };

        fetchUserData();
        fetchMetodosPago();
    }, []);

    const handleSubmit = async (values: { metodoPago: string }) => {
        if (!values.metodoPago) {
            setAlert({ open: true, message: "Seleccione un método de pago.", type: "warning" });
            return;
        }

        setMethodPayment(Number(values.metodoPago));
        setSubmitting(true);

        try {
            const order = await checkout();

            if (order === null) {  // ✅ Ahora sí podemos verificarlo correctamente
                return; // No se redirige si hubo un error
            }

            setAlert({ open: true, message: "Compra realizada con éxito.", type: "success" });
            navigate("/orderSuccess", { state: { order } });

        } catch (error) {
            console.error("❌ Error en checkout:", error);
            setAlert({ open: true, message: "Error al procesar la compra.", type: "error" });
        } finally {
            setSubmitting(false);
        }
    };

    if (loading || loadingUser) return <p>Cargando...</p>;

    const direccion = user?.direcciones?.[0];
    const telefono = user?.contactos?.[0];

    return (
        <MainLayout>
            <CustomAlert
                open={alert.open}
                message={alert.message}
                type={alert.type}
                onClose={() => setAlert({ ...alert, open: false })}
            />

            <BannerContainer>
                <Banner>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src="/img/giftwave_logo.png"
                            alt="GiftWave Logo"
                            style={{ width: '180px', height: 'auto', marginRight: '20px' }}
                        />
                        <div>
                            <Title>¡Estás a un paso de recibir tu GiftCard!</Title>
                            <Subtitle>Verifica los detalles y elige tu método de pago</Subtitle>
                        </div>
                    </div>
                </Banner>
            </BannerContainer>

            <CheckoutContainer>
                <LeftSection>
                    <Section>
                        <SectionTitle>Detalles de Facturación</SectionTitle>
                        {user && (
                            <>
                                <AddressField><strong>Nombre:</strong> {user.nombre} {user.apellido}</AddressField>
                                <AddressField><strong>Email:</strong> {user.email}</AddressField>
                                <AddressField><strong>Teléfono:</strong> {formatPhoneNumber(telefono)}</AddressField>
                                {direccion && (
                                    <>
                                        <AddressField><strong>Dirección:</strong> {direccion.calle} {direccion.numero}, {direccion.piso && `Piso ${direccion.piso},`} {direccion.departamento && `Dpto ${direccion.departamento}`}</AddressField>
                                        <AddressField><strong>Localidad:</strong> {direccion.localidad}, {direccion.provincia}</AddressField>
                                        <AddressField><strong>País:</strong> {direccion.pais}</AddressField>
                                        <AddressField><strong>Código Postal:</strong> {direccion.codigoPostal}</AddressField>
                                    </>
                                )}
                                <EditUserButton onClick={() => navigate("/profileEdit")}>
                                    <EditIcon /> Editar Datos
                                </EditUserButton>
                            </>
                        )}
                    </Section>

                    <Section>
                        <SectionTitle>Resumen de la Compra</SectionTitle>
                        {cartItems.length === 0 ? (
                            <p>El carrito está vacío.</p>
                        ) : (
                            <SummaryList>
                                {cartItems.map((item) => (
                                    <SummaryItem key={item.id}>
                                        <Miniatura src={item.imagenUrl} alt={item.nombre} />
                                        <span>{item.nombre}</span>
                                        <span>${(item.valor * item.quantity).toFixed(2)}</span>
                                        <div>
                                            <QuantityButton onClick={() => updateCartItem(item.id, Math.max(1, item.quantity - 1))}>
                                                <RemoveIcon />
                                            </QuantityButton>
                                            <span>{item.quantity}</span>
                                            <QuantityButton onClick={() => updateCartItem(item.id, item.quantity + 1)}>
                                                <AddIcon />
                                            </QuantityButton>
                                            <RemoveItemButton onClick={() => removeCartItem(item.id)}>
                                                <DeleteIcon />
                                            </RemoveItemButton>
                                        </div>
                                    </SummaryItem>
                                ))}
                            </SummaryList>
                        )}
                    </Section>
                    <Subtotal>
                        <span className="label">Subtotal:</span>
                        <span className="value">${cartItems.reduce((acc, item) => acc + item.valor * item.quantity, 0).toFixed(2)}</span>
                    </Subtotal>

                    {discountAmount > 0 && (
                        <Subtotal>
                            <span className="label">Descuento aplicado:</span>
                            <span className="discount">({discountCode}):- ${discountAmount.toFixed(2)}</span>
                        </Subtotal>
                    )}

                    <Subtotal>
                        <span className="label"><strong>Total:</strong></span>
                        <span className="value"><strong>${total.toFixed(2)}</strong></span>
                    </Subtotal>

                </LeftSection>

                {/* 🔹 SECCIÓN MÉTODO DE PAGO - AHORA FUNCIONA PERFECTO CON IMÁGENES */}
                <LeftSection>
                    <Section>
                        <SectionTitle>Método de Pago</SectionTitle>
                        <Form
                            onSubmit={handleSubmit}
                            initialValues={{ metodoPago: metodosPago.length > 0 ? metodosPago[0].id.toString() : "" }}
                            render={({ handleSubmit, form, values }) => (
                                <form onSubmit={handleSubmit}>
                                    {/* 🔹 Selección de Método de Pago */}
                                    <Field name="metodoPago">
                                        {({ input }) => (
                                            <PaymentMethodContainer>
                                                {metodosPago.map((metodo) => (
                                                    <PaymentMethodLabel
                                                        key={metodo.id}
                                                        selected={values.metodoPago === metodo.id.toString()}
                                                        onClick={() => form.change("metodoPago", metodo.id.toString())} // ✅ Asegura cambio
                                                    >
                                                        <input
                                                            type="radio"
                                                            value={metodo.id.toString()}
                                                            checked={values.metodoPago === metodo.id.toString()}
                                                            onChange={(e) => form.change("metodoPago", e.target.value)}
                                                        />
                                                        <PaymentMethodIconContainer>
                                                            {metodo.iconUrls.map((iconUrl, index) => (
                                                                <img key={index} src={iconUrl} alt={metodo.nombre} />
                                                            ))}
                                                        </PaymentMethodIconContainer>
                                                        {metodo.nombre}
                                                    </PaymentMethodLabel>
                                                ))}
                                            </PaymentMethodContainer>
                                        )}
                                    </Field>

                                    {/* 🔹 Formulario según método seleccionado */}
                                    {values.metodoPago === "1" && <CreditCardForm />}
                                    {values.metodoPago === "2" && <CreditCardForm />}
                                    {values.metodoPago === "3" && <TransferenciaForm />}
                                    {values.metodoPago === "4" && <MercadoPagoForm />}
                                    {values.metodoPago === "5" && <EfectivoForm />}

                                    {/* 🔹 Botón de Confirmar Compra */}
                                    <PaymentButton type="submit" disabled={submitting}>
                                        {submitting ? "Procesando..." : "Confirmar Compra"}
                                    </PaymentButton>
                                </form>
                            )}
                        />
                    </Section>
                </LeftSection>

            </CheckoutContainer>
        </MainLayout>
    );
};

export default Checkout;
