import React, { useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import api from "../../Services/api";
import CustomText from "../../Components/CustomFields/CustomText/CustomText";
import CustomNumber from "../../Components/CustomFields/CustomNumber/CustomNumber";
import CustomDate from "../../Components/CustomFields/CustomDate/CustomDate";
import CustomSelect from "../../Components/CustomFields/CustomSelect/CustomSelect";
import CustomPhone from "../../Components/CustomFields/CustomPhone/CustomPhone";
import CustomEmail from "../../Components/CustomFields/CustomEmail/CustomEmail";
import CustomPassword from "../../Components/CustomFields/CustomPassword/CustomPassword";
import { useGeneros, useEstadosCiviles, usePaises, useProvincias, useLocalidades } from "../../Hooks/useConfigData";
import CustomNotification from "../../Components/CustomNotification/CustomNotification";
import validateForm from "./validateForm";
import { AxiosError } from "axios";
import MainLayout from "../Layout/MainLayout";
import { BannerContainer, Column, FormGrid, RegisterContainer, RegisterFooter, SectionTitle, StyledLink } from "./style";
import CustomModal from "../../Components/CustomModal/CustomModal";
import { Banner, Button, Subtitle, Title } from "../Layout/style";
import { LinkText } from "../Login/style";

const Register: React.FC = () => {
    const navigate = useNavigate();
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState<"success" | "error">("success");

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [openErrorModal, setOpenErrorModal] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [validationMessages, setValidationMessages] = useState<string[]>([]);

    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState<"success" | "error" | "warning">("success");
    const [modalMessage, setModalMessage] = useState<string[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
    const [paisSeleccionado, setPaisSeleccionado] = useState<string | null>(null);
    const [provinciaSeleccionada, setProvinciaSeleccionada] = useState<string | null>(null);

    const { generos, loading: loadingGeneros } = useGeneros();
    const { estadosCiviles, loading: loadingEstadosCiviles } = useEstadosCiviles();
    const { paises, loading: loadingPaises } = usePaises();
    const { provincias, loading: loadingProvincias } = useProvincias(paisSeleccionado);
    const { localidades, loading: loadingLocalidades } = useLocalidades(provinciaSeleccionada);

    const handleRegister = async (values: any) => {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
                Object.entries(value).forEach(([subKey, subValue]) => {
                    formData.append(`${key}.${subKey}`, subValue);
                });
            } else {
                formData.append(key, String(value));
            }
        });

        try {
            await api.post("/api/usuario", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            setAlertMessage("¡Registro exitoso!");
            setAlertType("success");
            setOpenAlert(true);
            setTimeout(() => navigate("/login"), 3000);
        }
        catch (err) {
            const error = err as AxiosError<{ message?: string; validations?: { Code: string; Message: string }[] }>;

            if (error?.response?.data?.validations && error.response.data.validations.length > 0) {
                const newErrors: { [key: string]: string } = {};
                const validationMessages: string[] = [];
                setModalType("error");
                setModalMessage(error.response.data.validations.map(v => v.Message));
                setShowModal(true);

                error.response.data.validations.forEach((validation) => {
                    const match = validation.Message.match(/'(.+?)'/);
                    if (match) {
                        newErrors[match[1]] = validation.Message;
                    } else {
                        newErrors[`error_${validation.Code}`] = validation.Message;
                    }
                    validationMessages.push(validation.Message);
                });
                setFieldErrors(newErrors);
                setValidationMessages(validationMessages);
                setOpenErrorModal(true);
            } else if (error?.response?.data?.message) {
                setValidationMessages([error.response.data.message]);
                setOpenErrorModal(true);
                setModalType("error");
                setModalMessage([error.response.data.message]);
                setShowModal(true);
            } else {
                setAlertMessage("Error inesperado al registrar usuario.");
                setAlertType("error");
                setOpenAlert(true);
                setModalType("error");
                setModalMessage(["Ocurrió un error inesperado."]);
                setShowModal(true);
            }
        }
    };

    return (
        <MainLayout>
            <BannerContainer>
                <Banner>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src="/img/giftwave_logo.png"
                            alt="GiftWave Logo"
                            style={{ width: '180px', height: 'auto', marginRight: '20px' }}
                        />
                        <div>
                            <Title>Crea tu cuenta en GiftWave</Title>
                            <Subtitle>Completa el formulario y empieza a disfrutar de nuestros servicios</Subtitle>
                        </div>
                    </div>
                </Banner>
            </BannerContainer>

            <RegisterContainer>
                <CustomNotification
                    open={openAlert}
                    message={alertMessage}
                    type={alertType}
                    onClose={() => setOpenAlert(false)}
                />

                <CustomModal
                    open={showModal}
                    type={modalType}
                    title={modalType === "success" ? "¡Éxito!" : modalType === "error" ? "¡Error!" : "¡Atención!"}
                    messages={modalMessage}
                    onClose={() => setShowModal(false)}
                />

                <Form
                    onSubmit={handleRegister}
                    validate={validateForm}
                    render={({ handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit}>
                            {/* 📌 DATOS PERSONALES */}
                            <SectionTitle>Datos Personales</SectionTitle>
                            <FormGrid>
                                <Column>
                                    <CustomText name="nombre" label="Nombre(s)" required />
                                    <CustomNumber name="dni" label="DNI" />

                                </Column>
                                <Column>
                                    <CustomText name="apellido" label="Apellido(s)" />
                                    <CustomText name="cuil" label="CUIL" />
                                </Column>
                            </FormGrid>

                            <FormGrid>
                                <Column>
                                    <CustomDate name="fechaNacimiento" label="Fecha de Nacimiento" />

                                    {loadingGeneros ? <CircularProgress size={20} /> : (
                                        <CustomSelect
                                            name="generoId"
                                            label="Género"
                                            options={generos.map(g => ({ value: g.id, label: g.nombre }))}
                                            required
                                        />
                                    )}
                                </Column>

                                <Column>
                                    {loadingEstadosCiviles ? <CircularProgress size={20} /> : (
                                        <CustomSelect
                                            name="estadoCivilId"
                                            label="Estado Civil"
                                            options={estadosCiviles.map(e => ({ value: e.id, label: e.nombre }))}
                                            required
                                        />
                                    )}
                                </Column>

                            </FormGrid>

                            {/* 📌 DIRECCIÓN */}
                            <SectionTitle>Dirección</SectionTitle>
                            <FormGrid>
                                <Column>
                                    <CustomText name="direccion.calle" label="Calle" />
                                    <CustomNumber name="direccion.piso" label="Piso" />
                                </Column>
                                <Column>
                                    <CustomNumber name="direccion.numero" label="Número" />
                                    <CustomText name="direccion.departamento" label="Departamento" />
                                </Column>

                            </FormGrid>

                            <FormGrid>
                                <Column>
                                    <CustomNumber name="direccion.codigoPostal" label="Código Postal" />

                                    {loadingProvincias ? <CircularProgress size={20} /> : (
                                        <CustomSelect
                                            name="direccion.provinciaId"
                                            label="Provincia"
                                            options={provincias.map(p => ({ value: p.id, label: p.nombre }))}
                                            required
                                            onChange={(value) => setProvinciaSeleccionada(value)}
                                        />
                                    )}

                                </Column>
                                <Column>
                                    {loadingPaises ? <CircularProgress size={20} /> : (
                                        <CustomSelect
                                            name="direccion.paisId"
                                            label="País"
                                            options={paises.map(p => ({ value: p.id, label: p.nombre }))}
                                            required
                                            onChange={(value) => setPaisSeleccionado(value)}
                                        />
                                    )}

                                    {loadingLocalidades ? <CircularProgress size={20} /> : (
                                        <CustomSelect
                                            name="direccion.localidadId"
                                            label="Localidad"
                                            options={localidades.map(l => ({ value: l.id, label: l.nombre }))}
                                            required
                                        />
                                    )}
                                </Column>
                            </FormGrid>

                            {/* 📌 CONTACTO */}
                            <SectionTitle>Contacto</SectionTitle>
                            <FormGrid>
                                <Column>
                                    <CustomPhone name="contacto.codigoPais" label="Código País" required />
                                    <CustomText name="contacto.numero" label="Número de Teléfono" required />
                                </Column>

                                <Column>
                                    <CustomText name="contacto.codigoArea" label="Código Área" required />
                                </Column>
                            </FormGrid>

                            {/* 📌 DATOS DE LOGIN */}
                            <SectionTitle>Datos de Login</SectionTitle>
                            <FormGrid>
                                <Column>

                                    <CustomEmail name="email" label="Correo Electrónico" />
                                </Column>

                                <Column>

                                    <CustomPassword name="contraseña" label="Contraseña" />
                                    <CustomPassword name="confirmarContraseña" label="Confirmar Contraseña" required />
                                </Column>
                            </FormGrid>


                            <RegisterFooter>
                                <Button type="submit" color="primary" disabled={submitting}>
                                    {submitting ? "Registrando..." : "Registrarse"}
                                </Button>

                                <LinkText>
                                    ¿Ya estás registrado? <StyledLink href="/login">Hacer login</StyledLink>
                                </LinkText>
                            </RegisterFooter>


                        </form>
                    )}
                />
            </RegisterContainer>
        </MainLayout>
    );
};

export default Register;
