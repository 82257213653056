import React from "react";
import { useABM } from "../../../Hooks/abmConfig";
import { TipoUsuario } from "../../../Interfaces/backofficeInterfaces";
import { Container } from "../Components/style";
import FormComponent from "../Components/FormComponent/FormComponent";
import TableComponent from "../Components/TableComponent/TableComponent";
import CustomAlert from "../../../Components/CustomAlert/CustomAlert";

const TipoUsuarioPage: React.FC = () => {
    const { 
        items, 
        formData, 
        setFormData, 
        handleSave, 
        handleDelete, 
        handleEdit, 
        resetForm, 
        editingId, 
        alert, 
        setAlert, 
        confirmAlert, 
        setConfirmAlert 
    } = useABM<TipoUsuario>({
        endpoint: "api/TipoUsuario",
        defaultValues: { id: 0, nombre: "" },
    });

    return (
        <Container>
            <CustomAlert 
                open={alert.open} 
                message={alert.message} 
                type={alert.type} 
                onClose={() => setAlert({ ...alert, open: false })} 
            />

            {confirmAlert.open && (
                <CustomAlert 
                    open={confirmAlert.open} 
                    message={confirmAlert.message} 
                    type="warning" 
                    onConfirm={confirmAlert.onConfirm} 
                    onClose={() => setConfirmAlert({ open: false, message: "" })} 
                />
            )}

            <FormComponent
                formData={formData}
                setFormData={setFormData}
                fields={[{ name: "nombre", type: "text", placeholder: "Nombre" }]}
                onSubmit={handleSave}
                isEditing={editingId !== null}
                resetForm={resetForm}
            />
            
            <TableComponent
                headers={["ID", "Nombre"]}
                data={items}
                onEdit={handleEdit}
                onDelete={handleDelete} 
            />
        </Container>
    );
};

export default TipoUsuarioPage;
