import React, { useState } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Field } from "react-final-form";

interface CustomPasswordProps {
  name: string;
  label: string;
  required?: boolean;
}

const CustomPassword: React.FC<CustomPasswordProps> = ({ name, label, required }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Field name={name}>
      {({ input, meta }) => (
        <TextField
          {...input}
          type={showPassword ? "text" : "password"}
          label={label}
          required={required}
          fullWidth
          error={meta.touched && meta.error}
          helperText={meta.touched && meta.error ? meta.error : ""}
          sx={{
            backgroundColor: "#f0faff", 
            borderRadius: "5px", 
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    </Field>
  );
};

export default CustomPassword;
