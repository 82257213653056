import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  LayoutContainer,
  Sidebar,
  LogoContainer,
  Logo,
  SidebarItemButton,
  SidebarText,
  SubmenuContainer,
  SubmenuItem,
  Content,
  Header,
  TitlePage,
  LogoutButton,
} from "./style";
import { List, Collapse } from "@mui/material";
import CustomAlert from "../../../Components/CustomAlert/CustomAlert";

const BackofficeLayout: React.FC = () => {
  const navigate = useNavigate();
  const [confirmLogout, setConfirmLogout] = useState(false);
  const [openMenu, setOpenMenu] = useState<{ [key: string]: boolean }>({});
  const location = useLocation();

  const toggleMenu = (menu: string) => {
    setOpenMenu((prev) => ({ ...prev, [menu]: !prev[menu] }));
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.replace("/login");
  };

  const pageTitles: { [key: string]: string } = {
    "/backoffice/dashboard": "📊 Dashboard",

    // 👥 Usuarios
    "/backoffice/usuarios": "👥 Usuarios",
    "/backoffice/tiposUsuario": "👥 Tipos de Usuario",
    "/backoffice/estadosCiviles": "👥 Estados Civiles",
    "/backoffice/generos": "👥 Géneros",

    // 🛒 Ventas
    "/backoffice/ventas": "🛒 Ventas",
    "/backoffice/facturas": "🛒 Facturación",
    "/backoffice/metodosPago": "🛒 Métodos de Pago",
    "/backoffice/estadosVentas": "🛒 Estados de Ventas",
    "/backoffice/pagos": "🛒 Pagos",

    // 🎁 GiftCards
    "/backoffice/giftcards": "🎁 Gift Cards",
    "/backoffice/categorias": "🎁 Categorías",
    "/backoffice/estadosGiftCards": "🎁 Estados de GiftCards",

    // 🎟 Cupones
    "/backoffice/cupones": "🎟 Cupones",
    "/backoffice/aplicacionesCupones": "🎟 Aplicaciones de Cupones",

    // 🌍 Localización
    "/backoffice/paises": "🌍 Países",
    "/backoffice/provincias": "🌍 Provincias",
    "/backoffice/localidades": "🌍 Localidades",
    "/backoffice/direcciones": "🌍 Direcciones",
    "/backoffice/contactos": "🌍 Contactos",

    // ⚙ Configuración
    "/backoffice/resetPasswords": "⚙ Reset de Contraseñas",
    "/backoffice/historialMigraciones": "⚙ Historial de Migraciones",
};

const currentPageTitle = pageTitles[location.pathname] || "📊 Backoffice";

  /** ✅ Función genérica para navegar a cualquier sección */
  const goToSection = (section: string) => {
    navigate(`/backoffice/${section}`);
  };

  return (
    <LayoutContainer>
      <CustomAlert
        open={confirmLogout}
        message="¿Estás seguro de que quieres cerrar sesión?"
        type="warning"
        onConfirm={() => {
          setConfirmLogout(false);
          handleLogout();
        }}
        onClose={() => setConfirmLogout(false)}
      />

      {/* 🔹 Sidebar */}
      <Sidebar>
        <LogoContainer>
          <Logo src="/img/giftwave_logo.png" alt="GiftWave" onClick={() => navigate("/")} />
        </LogoContainer>

        <List>
          {/* 📊 Dashboard */}
          <SidebarItemButton onClick={() => goToSection("dashboard")}>
            📊 <SidebarText primary="Dashboard" />
          </SidebarItemButton>

          {/* 👥 Usuarios */}
          <SidebarItemButton onClick={() => toggleMenu("usuarios")}>
            👥 <SidebarText primary="Usuarios" /> {openMenu["usuarios"] ? <ExpandLess /> : <ExpandMore />}
          </SidebarItemButton>
          <Collapse in={openMenu["usuarios"]} timeout="auto" unmountOnExit>
            <SubmenuContainer>
              <SubmenuItem onClick={() => goToSection("usuarios")}>Listado de Usuarios</SubmenuItem>
              <SubmenuItem onClick={() => goToSection("tiposUsuario")}>Tipos de Usuario</SubmenuItem>
              <SubmenuItem onClick={() => goToSection("estadosCiviles")}>Estados Civiles</SubmenuItem>
              <SubmenuItem onClick={() => goToSection("generos")}>Géneros</SubmenuItem>
            </SubmenuContainer>
          </Collapse>

          {/* 🛒 Ventas */}
          <SidebarItemButton onClick={() => toggleMenu("ventas")}>
            🛒 <SidebarText primary="Ventas" /> {openMenu["ventas"] ? <ExpandLess /> : <ExpandMore />}
          </SidebarItemButton>
          <Collapse in={openMenu["ventas"]} timeout="auto" unmountOnExit>
            <SubmenuContainer>
              <SubmenuItem onClick={() => goToSection("ventas")}>Listado de Ventas</SubmenuItem>
              <SubmenuItem onClick={() => goToSection("facturas")}>Facturación</SubmenuItem>
              <SubmenuItem onClick={() => goToSection("metodosPago")}>Métodos de Pago</SubmenuItem>
              <SubmenuItem onClick={() => goToSection("estadosVentas")}>Estados de Ventas</SubmenuItem>
              <SubmenuItem onClick={() => goToSection("pagos")}>Pagos</SubmenuItem>
            </SubmenuContainer>
          </Collapse>

          {/* 🎁 GiftCards */}
          <SidebarItemButton onClick={() => toggleMenu("giftcards")}>
            🎁 <SidebarText primary="GiftCards" /> {openMenu["giftcards"] ? <ExpandLess /> : <ExpandMore />}
          </SidebarItemButton>
          <Collapse in={openMenu["giftcards"]} timeout="auto" unmountOnExit>
            <SubmenuContainer>
              <SubmenuItem onClick={() => goToSection("giftcards")}>Listado de GiftCards</SubmenuItem>
              <SubmenuItem onClick={() => goToSection("categorias")}>Categorías</SubmenuItem>
              <SubmenuItem onClick={() => goToSection("estadosGiftCards")}>Estados de GiftCards</SubmenuItem>
            </SubmenuContainer>
          </Collapse>

          {/* 🎟 Cupones */}
          <SidebarItemButton onClick={() => toggleMenu("cupones")}>
            🎟 <SidebarText primary="Cupones" /> {openMenu["cupones"] ? <ExpandLess /> : <ExpandMore />}
          </SidebarItemButton>
          <Collapse in={openMenu["cupones"]} timeout="auto" unmountOnExit>
            <SubmenuContainer>
              <SubmenuItem onClick={() => goToSection("cupones")}>Listado de Cupones</SubmenuItem>
              <SubmenuItem onClick={() => goToSection("aplicacionesCupones")}>Aplicaciones de Cupones</SubmenuItem>
            </SubmenuContainer>
          </Collapse>

          {/* 🌍 Localización */}
          <SidebarItemButton onClick={() => toggleMenu("localizacion")}>
            🌍 <SidebarText primary="Localización" /> {openMenu["localizacion"] ? <ExpandLess /> : <ExpandMore />}
          </SidebarItemButton>
          <Collapse in={openMenu["localizacion"]} timeout="auto" unmountOnExit>
            <SubmenuContainer>
              <SubmenuItem onClick={() => goToSection("paises")}>Países</SubmenuItem>
              <SubmenuItem onClick={() => goToSection("provincias")}>Provincias</SubmenuItem>
              <SubmenuItem onClick={() => goToSection("localidades")}>Localidades</SubmenuItem>
              <SubmenuItem onClick={() => goToSection("direcciones")}>Direcciones</SubmenuItem>
              <SubmenuItem onClick={() => goToSection("contactos")}>Contactos</SubmenuItem>
            </SubmenuContainer>
          </Collapse>

          {/* ⚙ Configuración */}
          <SidebarItemButton onClick={() => toggleMenu("configuracion")}>
            ⚙ <SidebarText primary="Configuración" /> {openMenu["configuracion"] ? <ExpandLess /> : <ExpandMore />}
          </SidebarItemButton>
          <Collapse in={openMenu["configuracion"]} timeout="auto" unmountOnExit>
            <SubmenuContainer>
              <SubmenuItem onClick={() => goToSection("resetPasswords")}>Reset de Contraseñas</SubmenuItem>
              <SubmenuItem onClick={() => goToSection("historialMigraciones")}>Historial de Migraciones</SubmenuItem>
            </SubmenuContainer>
          </Collapse>

          {/* 🔹 Botón de Logout */}
          <LogoutButton onClick={() => setConfirmLogout(true)}>
            <LogoutIcon /> Cerrar Sesión
          </LogoutButton>

        </List>
      </Sidebar>

      {/* 🔹 Contenido Principal */}
      <Content>
        <Header>
        <TitlePage>{currentPageTitle}</TitlePage>
        </Header>
        <Outlet />
      </Content>
    </LayoutContainer>
  );
};

export default BackofficeLayout;
