import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Edit, Delete, CheckCircle, Cancel } from "@mui/icons-material";
import { Table, TableCell, TableHeader, TableRow } from "../style";

interface TableProps<T> {
  headers: string[];
  data: T[];
  onEdit: (item: T) => void;
  onDelete: (id: number) => void;
}

const TableComponent = <T extends { id: number; estado?: boolean; fechaExpiracion?: string }>(
  { headers, data, onEdit, onDelete }: TableProps<T>
) => {
  const hasEstadoColumn = data.some((item) => item.estado !== undefined);

  return (
    <Table>
      <thead>
        <TableRow>
          {headers.map((header, index) => (
            <TableHeader key={index}>{header}</TableHeader>
          ))}
          {hasEstadoColumn && <TableHeader>Estado</TableHeader>}
          <TableHeader>Acciones</TableHeader>
        </TableRow>
      </thead>
      <tbody>
        {data.length > 0 ? (
          data.map((item) => (
            <TableRow key={item.id}>
              {/* 🔹 Renderizar todas las columnas excepto "estado" */}
              {Object.entries(item).map(([key, value]) => (
                key !== "estado" && ( // Evitar que se muestre `true/false` en texto
                  <TableCell key={key}>
                    {key === "fechaExpiracion" ? new Date(value as string).toLocaleDateString("es-ES") : String(value)}
                  </TableCell>
                )
              ))}

              {/* 🔥 Agregar manualmente la columna `estado` con íconos */}
              {hasEstadoColumn && (
                <TableCell>
                  {item.estado ? (
                    <>
                      <CheckCircle style={{ color: "green" }} /> <span style={{ color: "green" }}>Activo</span>
                    </>
                  ) : (
                    <>
                      <Cancel style={{ color: "red" }} /> <span style={{ color: "red" }}>Inactivo</span>
                    </>
                  )}
                </TableCell>
              )}

              {/* 🔥 Acciones */}
              <TableCell>
                <Tooltip title="Editar">
                  <IconButton onClick={() => onEdit(item)} color="primary">
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Eliminar">
                  <IconButton onClick={() => onDelete(item.id)} color="error">
                    <Delete />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={headers.length + (hasEstadoColumn ? 2 : 1)} style={{ textAlign: "center" }}>
              No hay registros disponibles
            </TableCell>
          </TableRow>
        )}
      </tbody>
    </Table>
  );
};

export default TableComponent;
