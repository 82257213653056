import React from "react";
import {
  ModalOverlay,
  ModalContent,
  ModalImageContainer,
  ModalImage,
  ModalFooter,
  ModalInfoContainer,
  ModalTitle,
  ModalCategory,
  ModalPrice,
  ModalDescription,
  ModalConditions,
  ModalButton,
} from "./styleModal";
import { GiftCard } from "../../Interfaces/formInterfaces";
import { useCart } from "../../Components/CartContext/CartContext";
import { Button } from "../Layout/style";

interface GiftCardModalProps {
  giftCard: GiftCard;
  onClose: () => void;
}

const GiftCardModal: React.FC<GiftCardModalProps> = ({ giftCard, onClose }) => {
  const { addToCart } = useCart(); // Hook del carrito

  const handleAddToCart = () => {
    addToCart({ ...giftCard, quantity: 1 }); // ✅ Asegura que tenga una cantidad inicial
    onClose(); // Cierra el modal
  };
  
  return (
    <ModalOverlay>
      <ModalContent>
        <ModalImageContainer>
          <ModalImage src={giftCard.imagenUrl} alt={giftCard.nombre} />
          <ModalFooter>
            <Button>Favoritos</Button>
            <ModalButton primary onClick={handleAddToCart}>
              Agregar al carrito
            </ModalButton>
            <Button onClick={onClose}>Cerrar</Button>
          </ModalFooter>
        </ModalImageContainer>

        <ModalInfoContainer>
          <ModalTitle>{giftCard.nombre}</ModalTitle>
          <ModalCategory>Categoría: {giftCard.categoria}</ModalCategory>
          <ModalPrice>Precio: ${giftCard.valor}</ModalPrice>
          <ModalDescription>
            <strong>Descripción:</strong> {giftCard.descripcion}
          </ModalDescription>
          <ModalConditions>
            <strong>Condiciones:</strong> {giftCard.condiciones}
          </ModalConditions>
        </ModalInfoContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default GiftCardModal;
