import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";                     
import CloseIcon from "@mui/icons-material/Close";                    
import CreditCardIcon from "@mui/icons-material/CreditCard";         
import HomeIcon from "@mui/icons-material/Home";                     
import AccountCircleIcon from "@mui/icons-material/AccountCircle";   
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";     
import InfoIcon from "@mui/icons-material/Info";                     
import PhoneIcon from "@mui/icons-material/Phone";                   
import SearchIcon from "@mui/icons-material/Search";                 
import LogoutIcon from "@mui/icons-material/Logout";                 
import {
  NavbarContainer,
  Logo,
  NavLinks,
  MenuItem,
  IconWrapper,
  IconContainer,
  ActionIcon,
  SearchContainer,
  SearchInput,
  SearchIconWrapper,
  MenuButton
} from "./style";
import useAuth from "../../Hooks/useAuth";
import ShoppingCart from "../../Components/ShoppingCart/ShoppingCart";
import CustomAlert from "../../Components/CustomAlert/CustomAlert";

const Navbar: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false); 
  const [confirmLogout, setConfirmLogout] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // Estado para el buscador

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleCart = () => {
    setCartOpen(!cartOpen); 
  };

  const autoOpenCart = () => {
    setCartOpen(true); 
  };

  const handleUserIconClick = () => {
    if (isAuthenticated) {
      navigate("/profile");
    } else {
      navigate("/login");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.replace("/login");
  };

  // 📌 Maneja la búsqueda
  const handleSearch = () => {
    if (searchQuery.trim() !== "") {
      navigate(`/giftCards?search=${encodeURIComponent(searchQuery)}`);
    }
  };

  // 📌 Ejecuta la búsqueda al presionar "Enter"
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <CustomAlert
        open={confirmLogout}
        message="¿Estás seguro de que quieres cerrar sesión?"
        type="warning"
        onConfirm={() => {
          setConfirmLogout(false);
          handleLogout();
        }}
        onClose={() => setConfirmLogout(false)}
      />

      <NavbarContainer>
        <Link to={"/"}>
          <Logo src="/img/giftwave_logo.png" alt="GiftWave Logo" />
        </Link>

        {/* 🔍 Buscador */}
        <SearchContainer>
          <SearchInput 
            placeholder="Buscar gift cards..." 
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={handleKeyPress} // Buscar al presionar Enter
          />
          <SearchIconWrapper onClick={handleSearch}>
            <SearchIcon />
          </SearchIconWrapper>
        </SearchContainer>

        {/* Botón de menú hamburguesa */}
        <MenuButton onClick={toggleMenu}>
          {menuOpen ? <CloseIcon /> : <MenuIcon />}
        </MenuButton>

        {/* Menú principal */}
        <NavLinks className={menuOpen ? "open" : ""}>
          <MenuItem>
            <Link to="/">
              <IconWrapper>
                <HomeIcon />
                <span>Inicio</span>
              </IconWrapper>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/about">
              <IconWrapper>
                <InfoIcon />
                <span>Acerca</span>
              </IconWrapper>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/giftCards">
              <IconWrapper>
                <CreditCardIcon />
                <span>Gift Cards</span>
              </IconWrapper>
            </Link>
          </MenuItem>
          {isAuthenticated && (
            <MenuItem>
              <Link to="/profile">
                <IconWrapper>
                  <AccountCircleIcon />
                  <span>Mi cuenta</span>
                </IconWrapper>
              </Link>
            </MenuItem>
          )}
          <MenuItem>
            <Link to="/contact">
              <IconWrapper>
                <PhoneIcon />
                <span>Contacto</span>
              </IconWrapper>
            </Link>
          </MenuItem>
        </NavLinks>

        <IconContainer>
          <ActionIcon onClick={toggleCart}>
            <ShoppingCartIcon />
          </ActionIcon>

          {!isAuthenticated && (
            <ActionIcon onClick={handleUserIconClick}>
              <AccountCircleIcon />
            </ActionIcon>
          )}

          {isAuthenticated && (
            <ActionIcon 
              onClick={() => setConfirmLogout(true)} 
              title="Cerrar sesión"
            >
              <LogoutIcon />
              <span>Salir</span>
            </ActionIcon>
          )}
        </IconContainer>
      </NavbarContainer>

      <ShoppingCart isOpen={cartOpen} onClose={toggleCart} autoOpen={autoOpenCart} />
    </>
  );
};

export default Navbar;
