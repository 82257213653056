import styled from 'styled-components';

// 📌 Capa de superposición del modal
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 1rem;
`;

// 📌 Contenedor del contenido del modal
export const ModalContent = styled.div`
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
  display: flex;
  padding: 2rem;
  overflow: hidden;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
  }
`;

// 📌 Contenedor de la imagen y botones
export const ModalImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin-right: 2rem;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

// 📌 Imagen en el modal
export const ModalImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  margin-bottom: 1rem;
`;

// 📌 Contenedor del footer con los botones de acción
export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
  justify-content: center;
  text-align: center;
`;

// 📌 Contenedor de la información del modal
export const ModalInfoContainer = styled.div`
  width: 60%;
  text-align: left;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

// 📌 Título del modal
export const ModalTitle = styled.h2`
  font-size: 2rem;
  color: #ff7e5f;
  margin-bottom: 1rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

// 📌 Categoría de la gift card en el modal
export const ModalCategory = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
`;

// 📌 Precio en el modal
export const ModalPrice = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #28a745;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

// 📌 Descripción de la tarjeta en el modal (con scroll si es necesario)
export const ModalDescription = styled.div`
  font-size: 1rem;
  color: #555;
  overflow-y: auto;
  max-height: 150px;
  margin-bottom: 1rem;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    font-size: 0.95rem;
    max-height: 120px;
  }
`;

// 📌 Condiciones de la tarjeta en el modal (con scroll si es necesario)
export const ModalConditions = styled.div`
  font-size: 1rem;
  color: #555;
  font-style: italic;
  overflow-y: auto;
  max-height: 150px;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    font-size: 0.95rem;
    max-height: 120px;
  }
`;

// 📌 Botones de acción en el modal (Favoritos, Agregar al carrito, Cerrar)
export const ModalButton = styled.button<{ primary?: boolean }>`
  background: ${({ primary }) => (primary ? 'linear-gradient(135deg, #ff7e5f, #feb47b)' : '#f0f0f0')};
  color: ${({ primary }) => (primary ? '#ffffff' : '#333')};
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 1rem;
  text-align: center;

  &:hover {
    background: ${({ primary }) => (primary ? '#e3675a' : '#e0e0e0')};
  }

  @media (max-width: 768px) {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
`;
