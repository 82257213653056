const validateForm = (values: any) => {
    const errors: { [key: string]: any } = {};

    // 📌 DATOS PERSONALES
    if (!values.nombre) {
        errors.nombre = "Nombre es obligatorio";
    } else if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/.test(values.nombre)) {
        errors.nombre = "Solo puede contener letras y espacios";
    }

    if (!values.apellido) {
        errors.apellido = "Apellido es obligatorio";
    } else if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/.test(values.apellido)) {
        errors.apellido = "Solo puede contener letras y espacios";
    }

    if (!values.dni) {
        errors.dni = "DNI es obligatorio";
    } else if (!/^\d{7,8}$/.test(values.dni)) {
        errors.dni = "Debe ser un número de 7 u 8 dígitos";
    }

    if (!values.cuil) {
        errors.cuil = "CUIL es obligatorio";
    }

    if (!values.fechaNacimiento) {
        errors.fechaNacimiento = "Fecha de nacimiento es obligatoria";
    }

    if (!values.generoId) {
        errors.generoId = "Género es obligatorio";
    }

    if (!values.estadoCivilId) {
        errors.estadoCivilId = "Estado Civil es obligatorio";
    }

    // 📌 DIRECCIÓN
    errors.direccion = {};
    if (!values.direccion?.calle) {
        errors.direccion.calle = "Calle es obligatoria";
    } else if (values.direccion.calle.length > 100) {
        errors.direccion.calle = "No debe exceder 100 caracteres";
    }

    if (!values.direccion?.numero) {
        errors.direccion.numero = "Número es obligatorio";
    } else if (!/^\d{1,6}$/.test(values.direccion.numero)) {
        errors.direccion.numero = "Debe tener entre 1 y 6 dígitos";
    }

    if (values.direccion?.piso && !/^\d{1,6}$/.test(values.direccion.piso)) {
        errors.direccion.piso = "Debe ser un número";
    }

    if (!values.direccion?.codigoPostal) {
        errors.direccion.codigoPostal = "Código Postal es obligatorio";
    } else if (!/^\d{4,5}$/.test(values.direccion.codigoPostal)) {
        errors.direccion.codigoPostal = "Debe tener 4 o 5 dígitos";
    }

    if (!values.direccion?.paisId) {
        errors.direccion.paisId = "País es obligatorio";
    }

    if (!values.direccion?.provinciaId) {
        errors.direccion.provinciaId = "Provincia es obligatoria";
    }

    if (!values.direccion?.localidadId) {
        errors.direccion.localidadId = "Localidad es obligatoria";
    }

    // Si no hay errores en dirección, eliminamos el objeto vacío
    if (Object.keys(errors.direccion).length === 0) delete errors.direccion;

    // 📌 CONTACTO
    errors.contacto = {};
    if (!values.contacto?.codigoPais) {
        errors.contacto.codigoPais = "Código de país es obligatorio";
    } else if (!/^\+?\d{1,4}$/.test(values.contacto.codigoPais)) {
        errors.contacto.codigoPais = "Debe tener entre 1 y 4 dígitos";
    }

    if (!values.contacto?.codigoArea) {
        errors.contacto.codigoArea = "Código de área es obligatorio";
    } else if (!/^\d{1,4}$/.test(values.contacto.codigoArea)) {
        errors.contacto.codigoArea = "Debe tener entre 1 y 4 dígitos";
    }

    if (!values.contacto?.numero) {
        errors.contacto.numero = "Número de teléfono es obligatorio";
    } else if (!/^\d{6,12}$/.test(values.contacto.numero)) {
        errors.contacto.numero = "Debe tener entre 6 y 12 dígitos";
    }

    // Si no hay errores en contacto, eliminamos el objeto vacío
    if (Object.keys(errors.contacto).length === 0) delete errors.contacto;

    // 📌 DATOS DE LOGIN
    if (!values.email) {
        errors.email = "Correo electrónico es obligatorio";
    } else if (!/^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/.test(values.email)) {
        errors.email = "Correo electrónico no válido";
    }

    if (!values.contraseña) {
        errors.contraseña = "Contraseña es obligatoria";
    } else if (
        values.contraseña.length < 8 ||
        !/[A-Z]/.test(values.contraseña) ||
        !/[a-z]/.test(values.contraseña) ||
        !/[!@#$%^&*(),.?":{}|<>]/.test(values.contraseña)
    ) {
        errors.contraseña = "Debe tener 8 caracteres, incluir mayúscula, minúscula y un carácter especial";
    }

    if (!values.confirmarContraseña) {
        errors.confirmarContraseña = "Confirmar contraseña es obligatorio";
    } else if (values.contraseña !== values.confirmarContraseña) {
        errors.confirmarContraseña = "Las contraseñas no coinciden";
    }

    return errors;
};

export default validateForm;
