import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom"; // 📌 Para obtener el parámetro "search"
import {
  GiftCardsContainer,
  CategoryFilter,
  CategoryItem,
  GiftCardsGrid,
  GiftCardItem,
  GiftCardImage,
  GiftCardTitle,
  GiftCardDescription,
  GiftCardPrice,
} from "./style";
import GiftCardModal from "./GiftCardModal";
import MainLayout from "../Layout/MainLayout";
import { GiftCard } from "../../Interfaces/formInterfaces";
import { useGiftCards, useCategorias } from "../../Hooks/useConfigData";
import { Banner, Button, Subtitle, Title } from "../Layout/style";
import { BannerContainer } from "../Register/style";

const GiftCards: React.FC = () => {
  const { giftCards } = useGiftCards();
  const { categorias } = useCategorias();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [filteredGiftCards, setFilteredGiftCards] = useState<GiftCard[]>([]);
  const [selectedGiftCard, setSelectedGiftCard] = useState<GiftCard | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams] = useSearchParams(); // 📌 Obtiene el parámetro "search" de la URL

  useEffect(() => {
    const searchQuery = searchParams.get("search")?.toLowerCase() || "";

    let filtered = giftCards;

    // 📌 Filtra por categoría si hay categorías seleccionadas
    if (selectedCategories.length > 0) {
      filtered = filtered.filter((giftCard) => selectedCategories.includes(giftCard.categoria));
    }

    // 📌 Filtra por búsqueda si hay un término de búsqueda ingresado
    if (searchQuery) {
      filtered = filtered.filter(
        (giftCard) =>
          giftCard.nombre.toLowerCase().includes(searchQuery) ||
          giftCard.descripcion.toLowerCase().includes(searchQuery)
      );
    }

    setFilteredGiftCards(filtered);
  }, [giftCards, selectedCategories, searchParams]); // Se ejecuta cuando cambian las GiftCards, categorías o el buscador

  const handleCategoryClick = (category: string) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const handleGiftCardClick = (giftCard: GiftCard) => {
    setSelectedGiftCard(giftCard);
    setIsModalOpen(true);
  };

  return (
    <MainLayout>
      <BannerContainer>
        <Banner>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="/img/giftwave_logo.png"
              alt="GiftWave Logo"
              style={{ width: "180px", height: "auto", marginRight: "20px" }}
            />
            <div>
              <Title>GiftCards y Experiencias !!!</Title>
              <Subtitle>Las mejores gift cards para todos los gustos</Subtitle>
            </div>
          </div>
        </Banner>
      </BannerContainer>

      <GiftCardsContainer>
        {/* 📌 FILTRO POR CATEGORÍA */}
        <CategoryFilter>
          {categorias.map((category) => (
            <CategoryItem
              key={category.id}
              selected={selectedCategories.includes(category.nombre)}
              onClick={() => handleCategoryClick(category.nombre)}
            >
              {category.nombre}
            </CategoryItem>
          ))}
        </CategoryFilter>

        {/* 📌 MOSTRAR GIFT CARDS */}
        <GiftCardsGrid>
          {filteredGiftCards.length > 0 ? (
            filteredGiftCards.map((giftCard) => (
              <GiftCardItem key={giftCard.id}>
                <GiftCardImage src={giftCard.imagenUrl} alt={giftCard.nombre} />
                <GiftCardTitle>{giftCard.nombre}</GiftCardTitle>
                <GiftCardPrice>Precio: ${giftCard.valor}</GiftCardPrice>
                <GiftCardDescription>
                  {giftCard.descripcion.length > 80
                    ? `${giftCard.descripcion.substring(0, 80)}...`
                    : giftCard.descripcion}
                </GiftCardDescription>
                <Button onClick={() => handleGiftCardClick(giftCard)}>Ver Detalles</Button>
              </GiftCardItem>
            ))
          ) : (
            <p>No se encontraron GiftCards.</p>
          )}
        </GiftCardsGrid>

        {/* 📌 MODAL DE DETALLE */}
        {isModalOpen && selectedGiftCard && (
          <GiftCardModal giftCard={selectedGiftCard} onClose={() => setIsModalOpen(false)} />
        )}
      </GiftCardsContainer>
    </MainLayout>
  );
};

export default GiftCards;
