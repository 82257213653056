import styled from 'styled-components';

// ✅ Contenedor principal del Navbar RESPONSIVO
export const NavbarContainer = styled.nav`
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1000;

  @media (max-width: 768px) {
    background: #b3e5fc;
    flex-direction: column;
    align-items: center;
  }
`;

// ✅ Logo RESPONSIVO
export const Logo = styled.img`
  width: 90px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 80px;
  }
`;

// ✅ Búsqueda RESPONSIVA
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background: #e3f2fd;
  border-radius: 16px;
  padding: 0.3rem 3rem;
  margin: 0 1.5rem;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 250px;
    margin: 0.5rem 0;
  }
`;

export const SearchInput = styled.input`
  border: none;
  outline: none;
  padding: 0.5rem;
  width: 200px;
  background: transparent;
  font-size: 1rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SearchIconWrapper = styled.div`
  cursor: pointer;
  font-size: 1.8rem;
  color: #0288D1;
  position: absolute;
  right: -1.5rem;
  transform: translateX(50%);

  @media (max-width: 768px) {
    right: 10px;
  }
`;

// ✅ Menú RESPONSIVO (oculto inicialmente en móviles)
export const NavLinks = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
  padding: 1.5rem 3rem;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    box-shadow: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #b3e5fc;
    z-index: 1000;
    transform: translateY(-200%);
    transition: transform 0.3s ease-in-out;
  }

  &.open {
    transform: translateY(0);
  }
`;

// ✅ Menú hamburguesa
export const MenuButton = styled.div`
  display: none;
  cursor: pointer;
  font-size: 2rem;
  color: #009688;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    right: 20px;
    top: 15px;
  }
`;

// ✅ Ítems del menú RESPONSIVOS
export const MenuItem = styled.div`
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    transform: translateY(-5px);
  }

  a {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
    padding: 0.8rem;
  }
`;

// ✅ Wrapper del ícono del menú RESPONSIVO
export const IconWrapper = styled.div`
  font-size: 1.8rem;
  color: #009688;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    font-size: 1rem;
    font-weight: bold;
    color: #444;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    span {
      font-size: 0.9rem;
    }
  }
`;

// ✅ Íconos de usuario y carrito RESPONSIVOS
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media (max-width: 768px) {
    gap: 1rem;
  }
`;

export const ActionIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #ff7e5f;
  cursor: pointer;
  transition: transform 0.3s, background 0.3s;
  padding: 0.5rem;
  border-radius: 8px;
  font-weight: bold; 

  &:hover {
    transform: scale(1.1);
    background-color: #e3f2fd;
  }

  span {
    font-size: 1.1rem; 
    color: #003366; 
  }

  svg {
    font-size: 2rem; 
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    span {
      display: none; /* Oculta el texto en móviles */
    }
  }
`;
