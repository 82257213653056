import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import api from "../../Services/api";
import CustomNotification from "../../Components/CustomNotification/CustomNotification";
import CustomModal from "../../Components/CustomModal/CustomModal";
import MainLayout from "../Layout/MainLayout";
import { FormGrid, ProfileContainer, SectionTitle, CardContainer } from "./style";
import { Banner, Subtitle, Title } from "../Layout/style";
import { BannerContainer, Column } from "../Register/style";
import SidebarMenu from "./SidebarMenu";
import { Usuario } from "../../Interfaces/compra";
import { Container } from "../MisCompras/style";


const UserProfile: React.FC = () => {
    const [user, setUser] = useState<Usuario | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState<string[]>([]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await api.get<Usuario>("/api/usuario/me", {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                });
                setUser(response.data);
            } catch (err) {
                setError("Error al cargar los datos del usuario.");
                setModalMessage(["No se pudieron obtener los datos del usuario."]);
                setShowModal(true);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    return (
        <MainLayout>
            <BannerContainer>
                <Banner>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src="/img/giftwave_logo.png"
                            alt="GiftWave Logo"
                            style={{ width: '180px', height: 'auto', marginRight: '20px' }}
                        />
                        <div>
                            <Title>Mi Cuenta</Title>
                            <Subtitle>Administra tu perfil, compras y más</Subtitle>
                        </div>
                    </div>
                </Banner>
            </BannerContainer>

            <Container>

                <SidebarMenu />

                <ProfileContainer>
                    {loading ? (
                        <CircularProgress />
                    ) : error ? (
                        <CustomNotification open={true} message={error} type="error" onClose={() => setError(null)} />
                    ) : (
                        <>
                            {/* 📌 DATOS PERSONALES */}
                            <SectionTitle>Datos Personales</SectionTitle>
                            <FormGrid>
                                <Column>
                                    <Typography><strong>Nombre(s):</strong> {user?.nombre}</Typography>
                                    <Typography><strong>DNI:</strong> {user?.dni}</Typography>
                                    <Typography><strong>Género:</strong> {user?.genero}</Typography>
                                </Column>
                                <Column>
                                    <Typography><strong>Apellido(s):</strong> {user?.apellido}</Typography>
                                    <Typography><strong>CUIL:</strong> {user?.cuil}</Typography>
                                    <Typography><strong>Estado Civil:</strong> {user?.estadoCivil}</Typography>
                                </Column>
                            </FormGrid>

                            {/* 📌 DIRECCIONES */}
                            <SectionTitle>Direcciones</SectionTitle>
                            <CardContainer>
                                {user?.direcciones?.length ? (
                                    user.direcciones.map((direccion, index) => (
                                        <Box key={direccion.direccionId} className="card">
                                            <Typography className="card-title">Dirección {index + 1}</Typography>
                                            <Divider />
                                            <Typography><strong>Calle:</strong> {direccion.calle}</Typography>
                                            <Typography><strong>Número:</strong> {direccion.numero}</Typography>
                                            {direccion.piso && <Typography><strong>Piso:</strong> {direccion.piso}</Typography>}
                                            {direccion.departamento && <Typography><strong>Departamento:</strong> {direccion.departamento}</Typography>}
                                            <Typography><strong>Código Postal:</strong> {direccion.codigoPostal}</Typography>
                                            <Typography><strong>Localidad:</strong> {direccion.localidad}</Typography>
                                            <Typography><strong>Provincia:</strong> {direccion.provincia}</Typography>
                                            <Typography><strong>País:</strong> {direccion.pais}</Typography>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography color="textSecondary">No hay direcciones registradas.</Typography>
                                )}
                            </CardContainer>

                            {/* 📌 CONTACTOS */}
                            <SectionTitle>Contactos</SectionTitle>
                            <CardContainer>
                                {user?.contactos?.length ? (
                                    user.contactos.map((contacto, index) => (
                                        <Box key={contacto.contactoId} className="card">
                                            <Typography className="card-title">Contacto {index + 1}</Typography>
                                            <Divider />
                                            <Typography><strong>Tipo:</strong> {contacto.tipo}</Typography>
                                            <Typography><strong>Código País:</strong> {contacto.codigoPais}</Typography>
                                            <Typography><strong>Código Área:</strong> {contacto.codigoArea}</Typography>
                                            <Typography><strong>Número:</strong> {contacto.numero}</Typography>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography color="textSecondary">No hay contactos registrados.</Typography>
                                )}
                            </CardContainer>
                        </>
                    )}

                    <CustomModal open={showModal} type="error" title="Error" messages={modalMessage} onClose={() => setShowModal(false)} />
                </ProfileContainer>
            </Container>
        </MainLayout>
    );
};

export default UserProfile;
