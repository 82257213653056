import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../Hooks/useAuth";

interface ProtectedRouteProps {
  children: React.ReactElement;
  adminOnly?: boolean;
  unauthenticatedOnly?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  adminOnly = false,
  unauthenticatedOnly = false,
}) => {
  const { isAuthenticated, loading, userRole } = useAuth();

  if (loading) return <div>Cargando...</div>;

  if (isAuthenticated) {
    if (userRole === "Administrador" && !adminOnly) return <Navigate to="/backoffice" replace />;
    if (unauthenticatedOnly) return <Navigate to="/" replace />;
  } else {
    if (!unauthenticatedOnly) return <Navigate to="/login" replace />;
  }

  if (adminOnly && userRole !== "Administrador") return <Navigate to="/" replace />;

  return children;
};

export default ProtectedRoute;
