import styled from "styled-components";

// Contenedor del Carrito de Compras
export const ShoppingCartContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
  width: 400px;
  height: 100vh;
  background:rgb(224, 239, 247);
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  padding: 1rem;
`;

export const ShoppingCartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  padding-bottom: 1rem;
  border-bottom: 2px solid #ddd;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

export const ShoppingCartItems = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 10px 0;
`;

export const ShoppingCartItem = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
`;

export const ItemRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  object-fit: cover;
`;

export const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 10px;
`;

export const ItemName = styled.span`
  font-size: 1rem;
  font-weight: bold;
`;

export const ItemPrice = styled.span`
  font-size: 1rem;
  color: #333;
  font-weight: bold;
  width: 80px;
  text-align: center;
`;

export const QuantityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 5px;

  button {
    background: #007bff;
    color: white;
    border: none;
    padding: 5px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    width: 25px;
    height: 25px;
    text-align: center;
  }
`;

export const RemoveButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: red;
  margin-left: 10px;

  &:hover {
    color: darkred;
  }
`;

export const ShoppingCartFooter = styled.div`
  border-top: 2px solid #ddd;
  padding-top: 10px;
`;


export const DiscountInput = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;

  input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
`;

export const Subtotal = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: bold;
  text-align: right;
  margin-bottom: 10px;
  margin-right: 50px;
  margin-left: 20px;

  span.label {
    color: #0056b3; 
  }

  span.value {
    color: #000; 
  }

  span.discount {
    color: #e63946; 
  }
`;

export const CheckoutButton = styled.button`
  width: 100%;
  padding: 10px;
  background: #ff9800;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 4px;

  &:hover {
    background: #e68900;
  }
`;
