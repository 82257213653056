import React, { useLayoutEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography, Divider } from "@mui/material";
import { Container, Content, CompraGroup, CompraItem, ActionsContainer, Fecha } from "./style";
import { Compra } from "../../Interfaces/compra";
import CompraDetalleModal from "./CompraDetalleModal";
import api from "../../Services/api";
import MainLayout from "../Layout/MainLayout";
import SidebarMenu from "../Profile/SidebarMenu";
import { Banner, Subtitle, Title } from "../Layout/style";
import { BannerContainer } from "../Register/style";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

const MisCompras: React.FC = () => {
    const [compras, setCompras] = useState<Compra[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedCompra, setSelectedCompra] = useState<Compra | null>(null);

    useLayoutEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get("/api/venta/me", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                setCompras(response.data.ventas);
            } catch (error) {
                console.error("Error obteniendo compras:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <CircularProgress />;

    // Agrupar compras por fecha
    const comprasAgrupadas = compras.reduce((acc, compra) => {
        const fecha = new Date(compra.fechaVenta).toLocaleDateString();
        if (!acc[fecha]) acc[fecha] = [];
        acc[fecha].push(compra);
        return acc;
    }, {} as Record<string, Compra[]>);

    return (
        <MainLayout>
            <BannerContainer>
                <Banner>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src="/img/giftwave_logo.png"
                            alt="GiftWave Logo"
                            style={{ width: '180px', height: 'auto', marginRight: '20px' }}
                        />
                        <div>
                            <Title>Mis Compras</Title>
                            <Subtitle>Administra tus compras fácilmente</Subtitle>
                        </div>
                    </div>
                </Banner>
            </BannerContainer>

            <Container>
                <SidebarMenu />

                <Content>
                    {Object.entries(comprasAgrupadas).map(([fecha, compras]) => (
                        <CompraGroup key={fecha}>
                            <Fecha>
                                📅 {fecha}
                            </Fecha>
                            <Divider sx={{ marginY: 1 }} />

                            {compras.map((compra) => {
                                const primeraGiftCard = " N.º " + compra.numeroComprobante + " (" + compra.detalles[0]?.nombre + ")"  || "Compra sin detalles";
                                const totalProductos = compra.detalles.length;
                                const tituloCompra = totalProductos > 1
                                    ? `${primeraGiftCard} `
                                    : primeraGiftCard;

                                return (
                                    <CompraItem key={compra.id}>
                                        <Box display="flex" alignItems="center">
                                            <ShoppingBagIcon style={{ marginRight: "15px" }} />
                                            <Box>
                                                <Typography variant="h6" fontWeight="bold" color="primary">
                                                    {tituloCompra}
                                                    {totalProductos > 1 && (
                                                        <Typography component="span" variant="body2" color="orange">
                                                            &nbsp;y {totalProductos - 1} más
                                                        </Typography>
                                                    )}
                                                </Typography>
                                                <Typography variant="body2" color="#3a6fa8">
                                                    {totalProductos} producto{totalProductos > 1 ? "s" : ""} • Total: ${compra.total ? compra.total.toFixed(2) : "0.00"}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <ActionsContainer>
                                            <Button variant="contained" color="primary" onClick={() => setSelectedCompra(compra)}>
                                                Ver compra
                                            </Button>
                                            <Button variant="outlined" color="secondary">
                                                Volver a comprar
                                            </Button>
                                        </ActionsContainer>
                                    </CompraItem>
                                );
                            })}
                        </CompraGroup>
                    ))}

                    {selectedCompra && (
                        <CompraDetalleModal compra={selectedCompra} onClose={() => setSelectedCompra(null)} />
                    )}
                </Content>
            </Container>
        </MainLayout>
    );
};

export default MisCompras;
