import { styled } from 'styled-components';
import backgroundImage from '../../assets/img/background.png';

export const LayoutContainer = styled.div`
  position: relative;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  min-height: auto; 
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8); 
    z-index: -1;
  }
`;

export const Banner = styled.div`
  background: #0099cc;
  color: white;
  padding: 3rem;
  border-radius: 16px;
  margin-bottom: 2rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center

  img {
    width: 200px;
    height: auto;
    margin-right: 20px;
    align-self: flex-start;
  }

  div {
    flex-grow: 1;
    text-align: center;
  }

  &:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }

  /** ✅ SOLO SE MODIFICA EN MÓVILES */
  @media (max-width: 768px) {
    padding: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      flex-direction: column;
      align-items: center;
    }

    img { 
      display: none; 
    }
  }
`;

export const Title = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  color: #ffffff; 
  margin-bottom: 1rem;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 1.7rem;
  }

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }

  @media (max-width: 480px) {
    font-size: 1.1rem;
  }
`;

export const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
  color: #ffffff;
  margin-bottom: 1rem;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.7rem;
  }
`;

// Interfaz para las propiedades del botón
interface ButtonProps {
  primaryColor?: string;
  secondaryColor?: string;
  fontSize?: string;
  padding?: string;
  align?: "left" | "center" | "right"; // Alineación
}

// Componente de botón estilizado
export const Button = styled.button<ButtonProps>`
  background: linear-gradient(
    135deg,
    ${({ primaryColor }) => primaryColor || "#007bff"},
    ${({ secondaryColor }) => secondaryColor || "#00bfff"}
  );
  color: #ffffff;
  border: none;
  padding: ${({ padding }) => padding || "0.8rem 1.2rem"};
  font-size: ${({ fontSize }) => fontSize || "1rem"};
  border-radius: 8px;
  cursor: pointer;
  margin: 1rem;
  transition: all 0.3s;
  display: block; 
  text-align: center;
  width: 100;
  
  /* 🔥 Alineación dinámica */
  ${({ align }) =>
    align === "center"
      ? "margin: 0rem auto;"
      : align === "right"
      ? "margin: 1rem 0 1rem auto;"
      : "margin: rem 0;"}

  &:hover {
    background: linear-gradient(
      135deg,
      ${({ primaryColor }) => primaryColor ? darkenColor(primaryColor, 20) : "#0056b3"},
      ${({ secondaryColor }) => secondaryColor ? darkenColor(secondaryColor, 20) : "#007bbf"}
    );
    transform: scale(1.05);
  }

  @media (max-width: 1024px) {
    font-size: ${({ fontSize }) => fontSize || "1.1rem"};
    padding: ${({ padding }) => padding || "0.7rem 1.8rem"};
  }

  @media (max-width: 768px) {
    font-size: ${({ fontSize }) => fontSize || "1rem"};
    padding: ${({ padding }) => padding || "0.6rem 1.5rem"};
  }

  @media (max-width: 480px) {
    font-size: ${({ fontSize }) => fontSize || "0.9rem"};
    padding: ${({ padding }) => padding || "0.5rem 1.2rem"};
    border-radius: 12px; 
  }
`;

// 🔥 Función para oscurecer el color en el hover
const darkenColor = (color: string, percent: number) => {
  let num = parseInt(color.replace("#", ""), 16),
    amt = Math.round(2.55 * percent),
    r = (num >> 16) - amt,
    g = ((num >> 8) & 0x00ff) - amt,
    b = (num & 0x0000ff) - amt;
  return `#${(0x1000000 + (r < 0 ? 0 : r) * 0x10000 + (g < 0 ? 0 : g) * 0x100 + (b < 0 ? 0 : b))
    .toString(16)
    .slice(1)}`;
};
