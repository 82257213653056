import styled from "styled-components";

export const CheckoutContainer = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
`;

export const LeftSection = styled.div`
  width: 55%;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
`;

export const SummaryList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const SummaryItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
`;

export const Miniatura = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 10px;
`;

export const DiscountInfo = styled.p`
  font-size: 16px;
  color: green;
  font-weight: bold;
  text-align: right;
`;

export const TotalPrice = styled.h3`
  font-size: 20px;
  font-weight: bold;
  text-align: right;
  color: #000;
  margin-top: 10px;
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectLabel = styled.label`
  font-size: 16px;
  font-weight: bold;
  color: #555;
  margin-bottom: 5px;
`;

export const PaymentButton = styled.button`
  width: 100%;
  padding: 12px;
  background: #ff7e5f;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  margin-top: 15px;

  &:hover {
    background: #e06b52;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

export const AddressField = styled.div`
  font-size: 14px;
  color: #666;
  padding: 5px 0;
`;

export const QuantityButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;

  &:hover {
    background: #0056b3;
  }
`;

export const RemoveItemButton = styled.button`
  background: #ff4d4d;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;

  &:hover {
    background: #cc0000;
  }
`;

export const EditUserButton = styled.button`
  background: #ffa500;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background: #e69500;
  }
`;

export const SuccessContainer = styled.div`
    text-align: center;
    padding: 30px;
    max-width: 600px;
    margin: 40px auto;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

export const IconSuccess = styled.div`
    color: #2ecc71;
    font-size: 60px; 
    margin-bottom: 15px;

    svg {
        font-size: 100px; 
    }
`;

export const SuccessMessage = styled.div`
    h2 {
        font-size: 22px;
        color: #333;
        margin-bottom: 5px;
    }
    p {
        font-size: 16px;
        color: #555;
        margin: 5px 0;
    }
    h3 {
        font-size: 18px;
        margin-top: 10px;
        color: #222;
        span {
            font-weight: bold;
            color: #2c3e50;
        }
    }
`;

export const OrderDetails = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const InfoBox = styled.div`
    background: rgb(240, 248, 255);
    padding: 15px;
    border-radius: 6px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    text-align: left;
`;

export const SectionHeader = styled.h4`
    font-size: 16px;
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
`;

export const SummaryTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
`;

export const SummaryRow = styled.tr`
    td {
        padding: 8px;
        border-bottom: 1px solid #ddd;
        font-size: 14px;
    }
`;

export const TotalBox = styled.div`
    background: #f8f8f8;
    padding: 15px;
    margin-top: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    
    p, h3 {
        font-size: 16px;
        margin: 5px 0;
    }

    h3 {
        font-size: 18px;
        font-weight: bold;
        color: #2c3e50;
    }
`;

export const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  min-height: 180px; /* Define un tamaño mínimo para evitar cambios bruscos */
  transition: all 0.3s ease-in-out;
`;

export const PaymentMethodContent = styled.div`
  width: 100%;
  margin-top: 10px;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease-in-out;

  &.active {
    opacity: 1;
    max-height: 300px; /* Define un tamaño adecuado */
  }
`;


// Estilos para cada opción de método de pago
export const PaymentMethodLabel = styled.label<{ selected: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px;
  border: ${({ selected }) => (selected ? "2px solid #007bff" : "1px solid #ccc")};
  border-radius: 5px;
  cursor: pointer;
  background: ${({ selected }) => (selected ? "#e3f2fd" : "#fff")};
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #f1f1f1;
  }

  input {
    display: none;
  }
`;

// Contenedor de los íconos de cada método de pago
export const PaymentMethodIconContainer = styled.div`
  display: flex;
  gap: 5px;

  img {
    max-width: 45px;
    height: auto;
    object-fit: contain;
  }
`;
