import { useState, useEffect, useCallback } from "react";
import api from "../Services/api";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { ABMConfig } from "../Interfaces/backofficeInterfaces";

export function useABM<T extends Record<string, any>>(config: ABMConfig<T>) {
    const [items, setItems] = useState<T[]>([]);
    const [formData, setFormData] = useState<T>(config.defaultValues);
    const [editingId, setEditingId] = useState<number | null>(null);
    
    // Estado para alertas normales
    const [alert, setAlert] = useState<{ open: boolean; message: string; type: "success" | "error" | "warning" }>({
        open: false,
        message: "",
        type: "success",
    });

    // Estado para confirmaciones
    const [confirmAlert, setConfirmAlert] = useState<{ open: boolean; message: string; onConfirm?: () => void }>({
        open: false,
        message: "",
    });

    const navigate = useNavigate();

    const getAuthHeaders = useCallback(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            setAlert({ open: true, message: "Tu sesión ha expirado. Inicia sesión nuevamente.", type: "warning" });
            navigate("/login");
        }
        return { headers: { Authorization: `Bearer ${token}` } };
    }, [navigate]);

    const handleError = useCallback((error: unknown, message: string) => {
        if (error instanceof AxiosError) {
            console.error(`${message}:`, error.response?.data || error.message);
            if (error.response?.status === 401 || error.response?.status === 403) {
                setAlert({ open: true, message: "No tienes permiso para realizar esta acción.", type: "error" });
                navigate("/login");
            } else {
                setAlert({ open: true, message: error.response?.data?.message || "Error en la solicitud.", type: "error" });
            }
        } else {
            console.error(`${message}:`, error);
            setAlert({ open: true, message: "Ocurrió un error inesperado.", type: "error" });
        }
    }, [navigate]);

    const fetchItems = useCallback(async () => {
        try {
            const response = await api.get(config.endpoint, getAuthHeaders());
            let data = response.data.tiposUsuario || response.data.cupones || response.data || [];

            if (!Array.isArray(data)) {
                console.error("⚠️ Error: la API no devolvió un array", response.data);
                setItems([]);
                return;
            }

            data = data.map((item) => ({
                ...item,
                id: item.id || item["id"],
            }));

            setItems(data);
        } catch (error) {
            handleError(error, "Error obteniendo datos.");
        }
    }, [config.endpoint, getAuthHeaders, handleError]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    const handleSave = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const { id, estado, ...payload } = formData;

            if (editingId === null) {
                await api.post(config.endpoint, payload, getAuthHeaders());
                setAlert({ open: true, message: "Registro guardado exitosamente.", type: "success" });
            } else {
                await api.put(`${config.endpoint}/${editingId}`, payload, getAuthHeaders());
                setAlert({ open: true, message: "Registro actualizado correctamente.", type: "success" });
            }

            fetchItems();
            resetForm();
        } catch (error) {
            handleError(error, "Error guardando datos.");
        }
    };

    const handleDelete = (id: number) => {
        setConfirmAlert({
            open: true,
            message: "¿Seguro que quieres eliminar este registro?",
            onConfirm: async () => {
                try {
                    await api.delete(`${config.endpoint}/${id}`, getAuthHeaders());
                    setAlert({ open: true, message: "Registro eliminado correctamente.", type: "success" });
                    fetchItems();
                } catch (error) {
                    setAlert({ open: true, message: "Error eliminando registro.", type: "error" });
                    handleError(error, "Error eliminando registro.");
                } finally {
                    setConfirmAlert({ open: false, message: "" });
                }
            },
        });
    };

    const handleEdit = (item: T) => {
        setFormData(item);
        setEditingId(item.id);
    };

    const updateFormData = (field: keyof T, value: any) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const resetForm = () => {
        setFormData(config.defaultValues);
        setEditingId(null);
    };

    return {
        items,
        formData,
        setFormData: updateFormData,
        handleSave,
        handleDelete,
        handleEdit,
        resetForm,
        editingId,
        alert, 
        setAlert,
        confirmAlert, 
        setConfirmAlert,
    };
}
