import styled, { keyframes } from 'styled-components';

// Animaciones
const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const float = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

// 📌 Sección de testimonios RESPONSIVE
export const TestimonialSection = styled.div`
  background: #e3f2fd;
  padding: 4rem 2rem;
  margin: 2rem 0;
  text-align: center;
  border-radius: 16px;
  animation: ${fadeIn} 2s ease-in-out;

  h3 {
    font-size: 2.5rem;
    color: #003366;
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    padding: 2rem;

    h3 {
      font-size: 2rem;
    }
  }

  @media (max-width: 480px) {
    padding: 1.5rem;

    h3 {
      font-size: 1.7rem;
    }
  }
`;

export const TestimonialContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* Ajusta dinámicamente el número de columnas */
  gap: 1.5rem;
  justify-content: center;
  align-items: stretch;

  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const TestimonialCard = styled.div`
  background: #ffffff;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  animation: ${fadeIn} 1.5s ease-in-out;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    max-width: 280px;
    width: 100%;
  }

  @media (max-width: 480px) {
    padding: 1.5rem;
  }
`;

export const TestimonialQuote = styled.blockquote`
  font-style: italic;
  font-size: 1.1rem;
  color: #003366;
  margin-bottom: 1.5rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

export const ClientName = styled.cite`
  display: block;
  color: #006699;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

// 📌 Estructura General Responsiva
export const HomeContainer = styled.div`
  padding: 2rem;
  text-align: center;
  color: #003366;
  animation: ${fadeIn} 1.5s ease-in-out;
`;

export const Description = styled.p`
  font-size: 1.1rem;
  color: #004d80;
  margin-bottom: 2rem;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  flex-wrap: wrap;

  div {
    text-align: center;

    img {
      width: 60px;
      height: 60px;
      margin-bottom: 0.5rem;
      transition: transform 0.3s;

      &:hover {
        animation: ${float} 2s ease-in-out infinite;
      }
    }

    p {
      font-size: 1rem;
      color: #003366;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    div {
      margin-bottom: 1rem;
    }
  }
`;

export const Section = styled.div`
  margin: 2rem 0;
  text-align: center;

  h3 {
    font-size: 2rem;
    color: #003366;
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 1.8rem;
    }
  }

  @media (max-width: 480px) {
    h3 {
      font-size: 1.5rem;
    }
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  svg {
    color: #ffb400; /* Icono de estrellas en color dorado */
  }

  @media (max-width: 768px) {
    svg {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 480px) {
    svg {
      font-size: 1rem;
    }
  }
`;

export const CarouselWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 1rem;
  padding: 1rem 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
  touch-action: pan-x;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 768px) {
    overflow-x: visible;
    touch-action: auto;
  }
`;


export const FeaturedContainer = styled.div`
  background: #b3e5fc;
  padding: 3rem;
  border-radius: 16px;
  margin: 2rem 0;
  text-align: center;
  animation: ${fadeIn} 1.5s ease-in-out;

  h3 {
    font-size: 2.5rem;
    color: #003366;
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    padding: 2rem;
    h3 {
      font-size: 2rem;
    }
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const CardContainer = styled.div`
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 16px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: calc(33% - 2rem);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  animation: ${fadeIn} 1.5s ease-in-out;

  .card-image {
    height: 100px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 1024px) {
    width: calc(50% - 2rem);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CardTitle = styled.h4`
  font-size: 1.5rem;
  text-align: center;
`;

export const CardDescription = styled.p`
  font-size: 1rem;
  text-align: center;
`;

