import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Register from './Pages/Register/Register';
import Login from './Pages/Login/Login';
import ProtectedRoute from './Components/ProtectedRoute';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import GiftCards from './Pages/GiftCard/GiftCards';
import BackofficeLayout from './Pages/Backoffice/BackofficeLayout/BackofficeLayout';
import Cupones from './Pages/Backoffice/Cupones/Cupones';
import TipoUsuario from './Pages/Backoffice/TipoUsuario/TipoUsuario';
import GiftCardsBO from './Pages/Backoffice/GiftCards/GiftCards';
import UserProfile from './Pages/Profile/UserProfile';
import UserEdit from './Pages/Profile/UserEdit';
import Checkout from './Pages/Checkout/Checkout';
import MisCompras from './Pages/MisCompras/MisCompras';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import OrderSucess from './Pages/Checkout/OrderSucess';
import Dashboard from './Pages/Backoffice/Dashboard/Dashboard';
import DynamicABMPage from './Pages/Backoffice/BackofficeLayout/AbmFicticio';

const router = createBrowserRouter(
  [
    { path: "/", element: <Home /> },
    { path: "/register", element: <ProtectedRoute unauthenticatedOnly><Register /></ProtectedRoute> },
    { path: "/login", element: <ProtectedRoute unauthenticatedOnly><Login /></ProtectedRoute> },
    { path: "/profile", element: <ProtectedRoute><UserProfile /></ProtectedRoute> },
    { path: "/profileEdit", element: <ProtectedRoute><UserEdit /></ProtectedRoute> },
    { path: "/miscompras", element: <ProtectedRoute><MisCompras /></ProtectedRoute> },
    { path: "/about", element: <About />},
    { path: "/contact", element: <Contact />},
    { path: "/giftCards", element: <GiftCards />},
    { path: "/checkout", element: <ProtectedRoute><Checkout /></ProtectedRoute>},
    { path: "/orderSuccess", element: <ProtectedRoute><OrderSucess /></ProtectedRoute>},
    { path: "/resetPassword", element: <ResetPassword />},

    {
      path: "/backoffice",
      element: <ProtectedRoute adminOnly><BackofficeLayout /></ProtectedRoute>,
      children: [
        { path: "dashboard", element: <Dashboard /> },
        { path: "/backoffice/tiposUsuario", element: <TipoUsuario /> },
        { path: "/backoffice/cupones", element: <Cupones /> },
        { path: "/backoffice/giftcards", element: <GiftCardsBO /> },
        { path: ":section", element: <DynamicABMPage /> },
      ],
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
    },
  }
);

export default router;
