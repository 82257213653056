import styled from "styled-components";

// Contenedor principal
export const Container = styled.div`
  padding: 2rem;
  background: #f5faff;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 0 auto;
`;

// Título de la página
export const Title = styled.h1`
  font-size: 2rem;
  color: #003366;
  text-align: center;
  margin-bottom: 1rem;
`;

// Botón de agregar GiftCard
export const AddButton = styled.button`
  background: linear-gradient(135deg, #28a745, #218838);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.1rem;
  border-radius: 8px;
  cursor: pointer;
  display: block;
  margin: 0 auto 1.5rem;
  transition: all 0.3s;

  &:hover {
    background: linear-gradient(135deg, #218838, #1e7e34);
  }
`;

// Tabla de GiftCards
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background: #f8f9fa;
  }
`;

export const TableHeader = styled.th`
  background: #003366;
  color: white;
  padding: 10px;
  text-align: left;
`;

export const TableCell = styled.td`
  padding: 12px;
  border-bottom: 1px solid #dee2e6;
`;

// Imagen en la tabla
export const Image = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  object-fit: cover;
`;

// Botones de acción (editar/eliminar)
export const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  margin-right: 8px;

  &:hover {
    opacity: 0.7;
  }
`;
