import React from "react";
import { FormContainer, Input, Select, TextArea, CheckboxLabel } from "../style";
import { Button } from "../../../Layout/style";

interface FormProps<T> {
  formData: T;
  setFormData: (field: keyof T, value: any) => void;
  fields: { name: keyof T; type: string; placeholder: string; options?: { value: string; label: string }[] }[];
  onSubmit: (event: React.FormEvent) => void;
  isEditing: boolean;
  resetForm: () => void;
}

const FormComponent = <T extends {}>({
  formData,
  setFormData,
  fields,
  onSubmit,
  isEditing,
  resetForm,
}: FormProps<T>) => {
  return (
    <FormContainer onSubmit={onSubmit}>
      {fields.map((field, idx) => {
        switch (field.type) {
          case "select":
            return (
              <Select key={idx} value={formData[field.name] as string} onChange={(e) => setFormData(field.name, e.target.value)}>
                {field.options?.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            );

          case "date":
            return (
              <Input
                key={idx}
                type="date"
                placeholder={field.placeholder}
                value={formData[field.name] as string}
                onChange={(e) => setFormData(field.name, e.target.value)}
              />
            );
          case "number":
            return (
              <Input
                key={idx}
                type="number"
                placeholder={field.placeholder}
                value={formData[field.name] as number}
                onChange={(e) => setFormData(field.name, Number(e.target.value))}
                required
              />
            );

          case "textarea":
            return (
              <TextArea
                key={idx}
                placeholder={field.placeholder}
                value={formData[field.name] as string}
                onChange={(e) => setFormData(field.name, e.target.value)}
                required
              />
            );

          case "checkbox":
            return (
              <CheckboxLabel key={idx}>
                <Input
                  type="checkbox"
                  checked={formData[field.name] as boolean}
                  onChange={(e) => setFormData(field.name, e.target.checked)}
                />
                {field.placeholder}
              </CheckboxLabel>
            );

          default:
            return (
              <Input
                key={idx}
                type="text"
                placeholder={field.placeholder}
                value={formData[field.name] as string}
                onChange={(e) => setFormData(field.name, e.target.value)}
                required
              />
            );
        }
      })}

      <Button type="submit">{isEditing ? "Actualizar" : "Agregar"}</Button>
      {isEditing && (
        <Button type="button" onClick={resetForm} style={{ background: "#dc3545" }}>
          Cancelar
        </Button>
      )}
    </FormContainer>
  );
};

export default FormComponent;
