import React, { useEffect, useRef, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LineChart, Line, PieChart, Pie, Cell, Legend } from "recharts";
import api from "../../../Services/api";
import { Button } from "../../Layout/style";
import { CardGrid, CardItem, CardItens, ChartContainer, ChartTitle, getMetodoPagoColor, LogContainer, LogItem, Section, StyledTitle } from "./style";

interface DashboardData {
    ventas: {
        totalVentas: number;
        ventasHoy: number;
        ventasMes: number;
        transacciones: number;
        ticketPromedioHoy: number;
        ventasPorMetodoPagoHoy: { metodoPagoId: number; metodoPagoNombre: string; total: number }[];
        ventasPorMetodoPagoMes: { metodoPagoId: number; metodoPagoNombre: string; total: number }[];

    };
    usuarios: {
        usuariosNuevos: number;
        usuariosActivos: number;
        usuariosConComprasRecientes: number;
        usuarioMayorComprador: { usuarioId: number; usuarioNombre: string; totalGastado: number };
    };
    pedidos: {
        pendientes: number;
        completados: number;
        cancelados: number;
        porcentajeCompletados: number;
        pedidosUltimos7Dias: number;
    };
    giftCards: { vendidas: number; canjeadas: number; saldoPendiente: number };
    cupones: {
        cuponesUsados: number;
        descuentosAplicados: number;
        cuponesMasUsados: { cuponId: number; cuponNombre: string; vecesUsado: number }[];
    };
    finanzas: {
        facturacionPorMes: { año: number; mes: number; totalFacturado: number }[];
        ingresosNetos: number;
        metodosPagoMasUsados: { metodoPagoId: number; metodoPagoNombre: string; cantidadVentas: number }[];
    };
}

interface LogsResponse {
    logs: string[];
    nextPageToken?: string;
}

const Dashboard: React.FC = () => {
    const [data, setData] = useState<DashboardData | null>(null);
    const [logs, setLogs] = useState<string[]>([]);
    const [logPageToken, setLogPageToken] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const isMounted = useRef(false);
    const formatCurrency = (value: number) => `$${value.toLocaleString("es-CL", { minimumFractionDigits: 2 })}`;

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await api.get("api/dashboard", {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setData(response.data);
        } catch (error) {
            console.error("❌ Error al obtener datos del dashboard:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchLogs = async (pageToken: string | null = null) => {
        try {
            const response = await api.get<LogsResponse>("api/logs", {
                params: { pageSize: 10, pageToken },
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setLogs(response.data.logs);
            setLogPageToken(response.data.nextPageToken || null);
        } catch (error) {
            console.error("❌ Error al obtener logs:", error);
        }
    };

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            fetchData();
            fetchLogs();
        }
    }, []);

    const parseLogMessage = (log: string) => {
        try {
            const dataMatch = log.match(/Data:\s*(\{.*\})/);
            const jsonData = dataMatch ? JSON.parse(dataMatch[1]) : null;

            return {
                timestamp: log.substring(2, 31),
                message: jsonData?.Message || "Mensaje no disponible",
                details: jsonData?.CustomFields ? JSON.parse(jsonData.CustomFields) : null,
                traceId: jsonData?.TraceId || "Sin TraceId",
                caller: jsonData?.Caller || "Sin Caller",
            };
        } catch (error) {
            console.error("❌ Error al parsear log:", error);
            return { timestamp: "Desconocido", message: log, details: null, traceId: "N/A", caller: "N/A" };
        }
    };

    if (loading) return <p className="text-lg text-gray-700">Cargando datos...</p>;
    if (!data) return <p className="text-red-500">Error al cargar datos</p>;

    return (
        <div className="max-w-7xl mx-auto p-6 space-y-6">
            <Button onClick={() => { fetchData(); fetchLogs(); }} className="mb-4">
                🔄 Refrescar Datos
            </Button>

            {/* 📌 Sección de Ventas */}
            <Section>
                <StyledTitle>📈 Ventas</StyledTitle>
                <CardGrid>
                    <CardItem>💰 Total Ventas: {formatCurrency(data.ventas.totalVentas)}</CardItem>
                    <CardItem>📆 Ventas Hoy: {formatCurrency(data.ventas.ventasHoy)}</CardItem>
                    <CardItem>📅 Ventas Mes: {formatCurrency(data.ventas.ventasMes)}</CardItem>
                    <CardItem>🔄 Transacciones: {data.ventas.transacciones}</CardItem>
                    <CardItem>🎟️ Ticket Promedio: {formatCurrency(data.ventas.ticketPromedioHoy)}</CardItem>
                </CardGrid>

                <ChartContainer>
                    <ChartTitle>📌 Ventas por Método de Pago Hoy</ChartTitle>
                    <ResponsiveContainer width="80%" height={300}>
                        <BarChart
                            data={data.ventas.ventasPorMetodoPagoHoy}
                            margin={{ top: 10, right: 30, left: 50, bottom: 10 }}
                        >
                            <XAxis dataKey="metodoPagoNombre" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="total" fill="#4A90E2" />
                        </BarChart>
                    </ResponsiveContainer>
                </ChartContainer>

                <ChartContainer>
                    <ChartTitle>📌 Ventas por Método de Pago Mes</ChartTitle>
                    <ResponsiveContainer width="80%" height={250}>
                        <BarChart data={data.ventas.ventasPorMetodoPagoMes}
                            margin={{ top: 10, right: 30, left: 50, bottom: 10 }}
                        >
                            <XAxis dataKey="metodoPagoNombre" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="total" fill="#50E3C2" />
                        </BarChart>
                    </ResponsiveContainer>
                </ChartContainer>
            </Section>

            {/* 📌 Sección de Finanzas */}
            <Section>
                <StyledTitle>💵 Finanzas</StyledTitle>
                <CardGrid>
                    <CardItem>📊 Ingresos Netos: {formatCurrency(data.finanzas.ingresosNetos)}</CardItem>
                </CardGrid>

                <ChartContainer>
                    <ChartTitle>📈 Facturación Mensual</ChartTitle>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={data.finanzas.facturacionPorMes}
                            margin={{ top: 10, right: 30, left: 50, bottom: 10 }}
                        >
                            <XAxis dataKey="mes" />
                            <YAxis />
                            <Tooltip />
                            <Line type="monotone" dataKey="totalFacturado" stroke="#82ca9d" />
                        </LineChart>
                    </ResponsiveContainer>
                </ChartContainer>

                <ChartContainer>
                    <ChartTitle>📌 Métodos de Pago Más Usados</ChartTitle>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie data={data.finanzas.metodosPagoMasUsados} dataKey="cantidadVentas" nameKey="metodoPagoNombre" outerRadius={100}>
                                {data.finanzas.metodosPagoMasUsados.map((metodo, index) => (
                                    <Cell key={`cell-${index}`} fill={getMetodoPagoColor(metodo.metodoPagoId)} />
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend />
                        </PieChart>
                    </ResponsiveContainer>
                </ChartContainer>
            </Section>

            {/* 📌 Sección de Pedidos */}
            <Section>
                <StyledTitle>📦 Pedidos</StyledTitle>
                <CardGrid>
                    <CardItens bgColor="#FFA500">⏳ Pendientes: {data.pedidos.pendientes}</CardItens>
                    <CardItens bgColor="#28A745">✅ Completados: {data.pedidos.completados}</CardItens>
                    <CardItens bgColor="#DC3545">❌ Cancelados: {data.pedidos.cancelados}</CardItens>
                    <CardItens bgColor="#17A2B8">📊 % Completados: {data.pedidos.porcentajeCompletados}%</CardItens>
                </CardGrid>
            </Section>

            {/* 📌 Sección de Usuarios */}
            <Section>
                <StyledTitle>👥 Usuarios</StyledTitle>
                <CardGrid>
                    <CardItens bgColor="#007BFF">🆕 Nuevos: {data.usuarios.usuariosNuevos}</CardItens>
                    <CardItens bgColor="#28A745">📌 Activos: {data.usuarios.usuariosActivos}</CardItens>
                    <CardItens bgColor="#FFC107">🛒 Compradores Recientes: {data.usuarios.usuariosConComprasRecientes}</CardItens>
                    <CardItens bgColor="#6F42C1">
                        🏆 Mayor Comprador: {data.usuarios.usuarioMayorComprador.usuarioNombre} -
                        {formatCurrency(data.usuarios.usuarioMayorComprador.totalGastado)}
                    </CardItens>
                </CardGrid>
            </Section>

            {/* 📌 Sección de Cupones */}
            <Section>
                <StyledTitle>🎟️ Cupones</StyledTitle>
                <CardGrid>
                    <CardItens bgColor="#E63946">🎟️ Usados: {data.cupones.cuponesUsados}</CardItens>
                    <CardItens bgColor="#F4A261">💸 Descuentos: {formatCurrency(data.cupones.descuentosAplicados)}</CardItens>
                </CardGrid>

                <ChartContainer>
                    <ChartTitle>📌 Cupones Más Usados</ChartTitle>
                    <ul className="p-4">
                        {data.cupones.cuponesMasUsados.map((cupon) => (
                            <li key={cupon.cuponId} className="p-2">
                                🎟️ {cupon.cuponNombre}: {cupon.vecesUsado} veces usado
                            </li>
                        ))}
                    </ul>
                </ChartContainer>
            </Section>

            {/* 📌 Sección de Logs con Paginación */}
            <Section>
                <StyledTitle>📜 Logs del Dashboard</StyledTitle>
                <LogContainer>
                    {logs.length > 0 ? (
                        logs.map((log, index) => {
                            const logData = parseLogMessage(log);
                            return (
                                <LogItem key={index}>
                                    <p><strong>🕒 {logData.timestamp}</strong></p>
                                    <p><strong>📌 {logData.message}</strong></p>
                                    {logData.details && (
                                        <pre className="bg-gray-200 p-2 rounded text-sm">{JSON.stringify(logData.details, null, 2)}</pre>
                                    )}
                                    <p className="text-sm text-gray-500">🔍 TraceID: {logData.traceId}</p>
                                    <p className="text-sm text-gray-500">📂 Caller: {logData.caller}</p>
                                </LogItem>
                            );
                        })
                    ) : (
                        <p>No hay logs disponibles.</p>
                    )}
                </LogContainer>

                <div className="flex justify-between mt-4">
                    <Button onClick={() => fetchLogs(null)} disabled={!logPageToken}>
                        ⏮️ Anterior
                    </Button>
                    <Button onClick={() => fetchLogs(logPageToken)} disabled={!logPageToken}>
                        ⏭️ Siguiente
                    </Button>
                </div>
            </Section>

        </div>
    );
};

export default Dashboard;
