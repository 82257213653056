import styled from "styled-components";

type AlertType = "success" | "error" | "warning";

interface AlertProps {
    type?: AlertType;
}

export const CustomAlertContainer = styled.div<AlertProps>`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  border-left: 6px solid;
  background-color: ${({ type }) =>
    type === "success" ? "#eafaea" : type === "error" ? "#fdecea" : "#f4efde"};
  color: ${({ type }) => (type === "success" ? "#1e4620" : type === "error" ? "#8b0000" : "#856404")};
  border-color: ${({ type }) =>
    type === "success" ? "#28a745" : type === "error" ? "#dc3545" : "#ff9800"};
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-width: fit-contain;
`;

export const AlertIcon = styled.div<AlertProps>`
  font-size: 24px;
  margin-right: 15px;
  color: ${({ type }) =>
        type === "success" ? "#28a745" : type === "error" ? "#dc3545" : "#ff9800"};
  display: flex;
  align-items: center;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
  position: absolute;
  top: 12px;
  right: 15px;
  &:hover {
    color: #000;
  }
`;

export const AlertContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const AlertTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const AlertMessage = styled.p`
  font-size: 14px;
  margin: 0;
`;

export const AlertButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
`;

export const ConfirmButton = styled.button`
  background-color: #3f3dd2;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  
  &:hover {
    background-color: #469357;
  }
`;

export const CancelButton = styled.button`
  background-color: #dd7220;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #c82333;
  }
`;
