import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../Layout/MainLayout";
import {
    SuccessContainer,
    SuccessMessage,
    OrderDetails,
    InfoBox,
    IconSuccess,
    SectionHeader,
    SummaryTable,
    SummaryRow,
    TotalBox
} from "./style";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button } from "../Layout/style";
import CustomAlert from "../../Components/CustomAlert/CustomAlert";

const OrderSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [alert, setAlert] = useState<{ open: boolean; message: string; type: "success" | "error" | "warning" }>({
        open: false,
        message: "",
        type: "success",
    });

    const order = location.state?.order;

    useEffect(() => {
        if (!order || !order.detalles) {
            setAlert({ open: true, message: "No se encontraron detalles de la compra.", type: "error" });
            setTimeout(() => navigate("/"), 3000);
        }
    }, [order, navigate]);

    if (!order) return null;

    return (
        <MainLayout>
            <CustomAlert
                open={alert.open}
                message={alert.message}
                type={alert.type}
                onClose={() => setAlert({ ...alert, open: false })}
            />

            <SuccessContainer>
                <IconSuccess>
                    <CheckCircleIcon />
                </IconSuccess>

                <SuccessMessage>
                    <h2>¡Gracias por tu compra!</h2>
                    <p>Tu pedido fue recibido con éxito.</p>
                    <p>Revisá tu correo para más detalles.</p>
                    <h3>Pedido: <span>{order.numeroComprobante}</span></h3>
                    <p><strong>Fecha:</strong> {new Date(order.fechaFactura).toLocaleString()}</p>
                </SuccessMessage>

                <OrderDetails>
                    {/* 🟢 MÉTODO DE PAGO */}
                    <InfoBox>
                        <SectionHeader>💳 Método de Pago</SectionHeader>
                        <p>{order.metodoPago || "No especificado"}</p>
                    </InfoBox>

                    {/* 🟢 RESUMEN DE COMPRA */}
                    <InfoBox>
                        <SectionHeader>📄 Resumen de Compra</SectionHeader>
                        <SummaryTable>
                            {order.detalles.map((item: any) => (
                                <SummaryRow key={item.giftCardId}>
                                    <td>{item.nombre || "GiftCard"}</td>
                                    <td>Cant: {item.cantidad}</td>
                                    <td>${(item.precioUnitario * item.cantidad).toFixed(2)}</td>
                                </SummaryRow>
                            ))}
                        </SummaryTable>
                    </InfoBox>
                </OrderDetails>

                {/* 🟢 TOTAL */}
                <TotalBox>
                    <p><strong>Subtotal:</strong> ${order.subtotal.toFixed(2)}</p>
                    {order.descuento > 0 && <p><strong>Descuento:</strong> -${order.descuento.toFixed(2)}</p>}
                    <h3><strong>Total Pagado:</strong> ${order.total.toFixed(2)}</h3>
                </TotalBox>

                {/* 🟢 BOTÓN FINAL */}
                <Button onClick={() => navigate("/misCompras")}>Ver mis compras</Button>
            </SuccessContainer>
        </MainLayout>
    );
};

export default OrderSuccess;
