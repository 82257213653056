import React from "react";
import { TextField } from "@mui/material";
import { Field } from "react-final-form";

interface CustomPhoneProps {
  name: string;
  label: string;
  required?: boolean;
}

const CustomPhone: React.FC<CustomPhoneProps> = ({ name, label, required }) => {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <TextField
          {...input}
          type="tel"
          label={label}
          required={required}
          fullWidth
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error ? meta.error : ""}
          inputProps={{
            pattern: "[0-9]+",
            placeholder: "Ejemplo: 1234567890",
          }}
          onChange={(e) => {
            const value = e.target.value.replace(/\D/g, ""); 
            input.onChange(value);
          }}
        />
      )}
    </Field>
  );
};

export default CustomPhone;
