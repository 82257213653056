import React, { useLayoutEffect, useState } from "react";
import {
    HomeContainer,
    Description,
    FeaturedContainer,
    CardContainer,
    CardTitle,
    CardDescription,
    IconContainer,
    Section,
    TestimonialCard,
    TestimonialQuote,
    TestimonialSection,
    RatingContainer,
    ClientName,
    CarouselWrapper,
    CardsWrapper,
} from "./style";
import MainLayout from "../Layout/MainLayout";
import { Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import { Banner, Button, Subtitle, Title } from "../Layout/style";
import { BannerContainer } from "../Register/style";
import { GiftCard } from "../../Interfaces/formInterfaces";
import api from "../../Services/api";

const Home: React.FC = () => {
    const [topGiftCards, setTopGiftCards] = useState<GiftCard[]>([]);

    useLayoutEffect(() => {
        const fetchTopGiftCards = async () => {
            try {
                const response = await api.get("/api/giftcard/top");
                setTopGiftCards(response.data);
            } catch (error) {
                console.error("Error al obtener el top de GiftCards", error);
            }
        };

        fetchTopGiftCards();
    }, []);

    return (
        <MainLayout>
            <BannerContainer>
                <Banner>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src="/img/giftwave_logo.png"
                            alt="GiftWave Logo"
                            style={{ width: "200px", height: "auto", marginRight: "20px" }}
                        />
                        <div>
                            <Title>Descubre el mundo de GiftWave</Title>
                            <Subtitle>Las mejores gift cards para todos los gustos</Subtitle>
                            <Button align="center">Explorar Gift Cards</Button>
                        </div>
                    </div>
                </Banner>
            </BannerContainer>

            <HomeContainer>
                <Section>
                    <h3>¿Quiénes somos?</h3>
                    <Description>
                        En GiftWave, nos dedicamos a ofrecer una amplia variedad de tarjetas de regalo
                        para todas las ocasiones. Encuentra desde tarjetas de marcas reconocidas hasta
                        experiencias inolvidables para compartir con quienes más quieres.
                    </Description>
                </Section>

                <FeaturedContainer>
                    <h3>Top 5 Gift Cards Más Vendidas</h3>
                    <CarouselWrapper>
                        {topGiftCards.length > 0 ? (
                            <CardsWrapper>
                                {topGiftCards.map((giftCard) => (
                                    <CardContainer key={giftCard.id}>
                                        <div className="card-image">
                                            <img src={giftCard.imagenUrl} alt={giftCard.nombre} />
                                        </div>
                                        <CardTitle>
                                            {giftCard.ranking}. {giftCard.nombre}
                                        </CardTitle>
                                        <CardDescription>
                                            {giftCard.descripcion.length > 80
                                                ? `${giftCard.descripcion.substring(0, 80)}...`
                                                : giftCard.descripcion}
                                        </CardDescription>
                                        <Typography variant="body2">
                                            Vendido {giftCard.totalVentas} veces
                                        </Typography>
                                    </CardContainer>
                                ))}
                            </CardsWrapper>
                        ) : (
                            <p>Cargando GiftCards...</p>
                        )}
                    </CarouselWrapper>
                </FeaturedContainer>

                <Section>
                    <h3>¿Por qué elegir GiftWave?</h3>
                    <Description>
                        En GiftWave hacemos que regalar sea una experiencia única, rápida y segura.
                        Estos son algunos de los beneficios que te ofrecemos para que puedas disfrutar de
                        un proceso sin complicaciones.
                    </Description>
                    <IconContainer>
                        <div>
                            <img src="/img/facil.png" alt="Fácil" />
                            <Typography variant="h6">Fácil de Usar</Typography>
                            <Typography variant="body2">
                                Encuentra la gift card perfecta en pocos clics con nuestro diseño intuitivo.
                            </Typography>
                        </div>
                        <div>
                            <img src="/img/PagoSeguro.png" alt="Seguro" />
                            <Typography variant="h6">Pagos 100% Seguros</Typography>
                            <Typography variant="body2">
                                Protegemos tus datos con tecnología avanzada de encriptación.
                            </Typography>
                        </div>
                        <div>
                            <img src="/img/variedad.png" alt="Variedad" />
                            <Typography variant="h6">Amplia Variedad</Typography>
                            <Typography variant="body2">
                                Ofrecemos gift cards de tus marcas favoritas para ropa, juegos, restaurantes y más.
                            </Typography>
                        </div>
                    </IconContainer>
                </Section>

                <TestimonialSection>
                    <h3>Testimonios de nuestros clientes</h3>
                    <CarouselWrapper>
                        <TestimonialCard>
                            <TestimonialQuote>
                                "GiftWave me facilitó encontrar el regalo perfecto para mi hermana, muy recomendable!"
                            </TestimonialQuote>
                            <RatingContainer>
                                <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarHalfIcon />
                            </RatingContainer>
                            <ClientName>- Carlos M., cliente satisfecho</ClientName>
                        </TestimonialCard>
                        <TestimonialCard>
                            <TestimonialQuote>
                                "Me encanta la variedad de tarjetas que ofrecen, siempre encuentro la ideal para cada ocasión."
                            </TestimonialQuote>
                            <RatingContainer>
                                <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon />
                            </RatingContainer>
                            <ClientName>- Laura G., compradora habitual</ClientName>
                        </TestimonialCard>
                        <TestimonialCard>
                            <TestimonialQuote>
                                "Muy fácil de usar, la experiencia fue genial y las opciones son realmente variadas."
                            </TestimonialQuote>
                            <RatingContainer>
                                <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon />
                            </RatingContainer>
                            <ClientName>- Andrés P., cliente feliz</ClientName>
                        </TestimonialCard>
                        <TestimonialCard>
                            <TestimonialQuote>
                                "GiftWave me ayudó a elegir una gift card para un compañero de trabajo, y todo fue muy rápido y seguro."
                            </TestimonialQuote>
                            <RatingContainer>
                                <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarHalfIcon />
                            </RatingContainer>
                            <ClientName>- Natalia R., usuaria nueva</ClientName>
                        </TestimonialCard>
                    </CarouselWrapper>
                </TestimonialSection>
            </HomeContainer>
        </MainLayout>
    );
};

export default Home;
