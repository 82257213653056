import { useEffect, useRef } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import {
  ShoppingCartContainer,
  ShoppingCartHeader,
  CloseButton,
  ShoppingCartItems,
  ShoppingCartItem,
  ItemImage,
  ItemDetails,
  ItemName,
  ItemPrice,
  QuantityControls,
  ShoppingCartFooter,
  Subtotal,
  DiscountInput,
  RemoveButton,
  ItemRow
} from "./styles";
import { useCart } from "../CartContext/CartContext";
import { Button } from "../../Pages/Layout/style";
import { useNavigate } from "react-router-dom";

const ShoppingCart = ({ isOpen, onClose, autoOpen }: { isOpen: boolean; onClose: () => void; autoOpen: () => void }) => {
  const {
    cartItems,
    updateCartItem,
    removeCartItem,
    validateDiscount,
    discountCode,
    discountAmount,
    total,
    setDiscountCode,
    setDiscountAmount,
    setIsDiscountApplied
  } = useCart();
  const navigate = useNavigate();
  const location = useLocation();
  const prevCartSnapshot = useRef(JSON.stringify(cartItems));

  useEffect(() => {
    if (location.pathname === "/checkout" && isOpen) {
      onClose();
    }
  }, [location.pathname, isOpen, onClose]);

  useEffect(() => {
    const cartSnapshot = JSON.stringify(cartItems);
    if (cartSnapshot !== prevCartSnapshot.current) {
      autoOpen();
    }
    prevCartSnapshot.current = cartSnapshot;
  }, [cartItems, autoOpen]);

  const handleCheckout = () => {
    if (cartItems.length === 0) return;
    onClose();
    setTimeout(() => {
      navigate("/checkout");
    }, 100);
  };

  const removeDiscount = () => {
    setDiscountCode("");
    setDiscountAmount(0);
    setIsDiscountApplied(false);
  };

  return (
    <ShoppingCartContainer isOpen={isOpen}>
      <ShoppingCartHeader>
        Carrito de Compras
        <CloseButton onClick={onClose}>✖</CloseButton>
      </ShoppingCartHeader>

      <ShoppingCartItems>
        {cartItems.length === 0 ? (
          <p>El carrito está vacío</p>
        ) : (
          cartItems.map((item) => (
            <ShoppingCartItem key={item.id}>
              <ItemRow>
                <ItemImage src={item.imagenUrl} alt={item.nombre} />
                <ItemDetails>
                  <ItemName>{item.nombre}</ItemName>
                  <QuantityControls>
                    <button onClick={() => updateCartItem(item.id, Math.max(1, item.quantity - 1))}>-</button>
                    <span>{item.quantity}</span>
                    <button onClick={() => updateCartItem(item.id, item.quantity + 1)}>+</button>
                  </QuantityControls>
                </ItemDetails>
                <ItemPrice>${(item.valor * item.quantity).toFixed(2)}</ItemPrice>
                <RemoveButton onClick={() => removeCartItem(item.id)}>
                  <DeleteIcon />
                </RemoveButton>
              </ItemRow>
            </ShoppingCartItem>
          ))
        )}
      </ShoppingCartItems>

      <ShoppingCartFooter>
        <DiscountInput>
          <input
            type="text"
            placeholder="Código de descuento"
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value)}
            disabled={discountAmount > 0}
          />
          {discountAmount > 0 ? (
            <Button onClick={removeDiscount}>❌</Button> // Botón de eliminar cupón
          ) : (
            <Button onClick={validateDiscount}>Aplicar</Button> // Botón de aplicar cupón
          )}
        </DiscountInput>

        <Subtotal>
          <span className="label">Subtotal:</span>
          <span className="value">${cartItems.reduce((acc, item) => acc + item.valor * item.quantity, 0).toFixed(2)}</span>
        </Subtotal>

        {discountAmount > 0 && (
          <Subtotal>
            <span className="label">Descuento aplicado:</span>
            <span className="discount">- ${discountAmount.toFixed(2)}</span>
          </Subtotal>
        )}

        <Subtotal>
          <span className="label"><strong>Total:</strong></span>
          <span className="value"><strong>${total.toFixed(2)}</strong></span>
        </Subtotal>

        <Button
          onClick={handleCheckout}
          padding="0.6rem 5rem"
          disabled={cartItems.length === 0}
        >
          Finalizar Compra
        </Button>
      </ShoppingCartFooter>
    </ShoppingCartContainer>
  );
};

export default ShoppingCart;
