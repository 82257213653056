import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, FormContainer, Input, FormButton, Table, TableRow, TableHeader, TableCell } from "./style";
import CustomAlert from "../../../Components/CustomAlert/CustomAlert";
import { IconButton, Tooltip } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

// Datos ficticios para cada sección del menú
const dataSets: { [key: string]: any[] } = {
    usuarios: [
        { id: 1, nombre: "Juan Pérez", email: "juan.perez@example.com", tipoUsuario: "Administrador", estado: true },
        { id: 2, nombre: "María Gómez", email: "maria.gomez@example.com", tipoUsuario: "Cliente", estado: true },
        { id: 3, nombre: "Carlos Ruiz", email: "carlos.ruiz@example.com", tipoUsuario: "Moderador", estado: false },
        { id: 4, nombre: "Ana López", email: "ana.lopez@example.com", tipoUsuario: "Cliente", estado: true },
        { id: 5, nombre: "Pedro Fernández", email: "pedro.fernandez@example.com", tipoUsuario: "Administrador", estado: true },
        { id: 6, nombre: "Laura Martínez", email: "laura.martinez@example.com", tipoUsuario: "Cliente", estado: false },
        { id: 7, nombre: "Diego Castro", email: "diego.castro@example.com", tipoUsuario: "Moderador", estado: true },
        { id: 8, nombre: "Carla Ríos", email: "carla.rios@example.com", tipoUsuario: "Cliente", estado: true },
        { id: 9, nombre: "Jorge Ramírez", email: "jorge.ramirez@example.com", tipoUsuario: "Administrador", estado: false },
        { id: 10, nombre: "Mónica Torres", email: "monica.torres@example.com", tipoUsuario: "Cliente", estado: true }
    ],
    tiposUsuario: [
        { id: 1, tipo: "Administrador" },
        { id: 2, tipo: "Usuario Regular" },
        { id: 3, tipo: "Moderador" },
    ],
    estadosCiviles: [
        { id: 1, descripcion: "Soltero/a" },
        { id: 2, descripcion: "Casado/a" },
        { id: 3, descripcion: "Divorciado/a" },
        { id: 4, descripcion: "Viudo/a" },
        { id: 5, descripcion: "Unión libre" },
        { id: 6, descripcion: "Separado/a" },
        { id: 7, descripcion: "Comprometido/a" },
        { id: 8, descripcion: "No especificado" },
        { id: 9, descripcion: "Otro" },
        { id: 10, descripcion: "Prefiero no decirlo" }
    ],
    generos: [
        { id: 1, nombre: "Masculino" },
        { id: 2, nombre: "Femenino" },
        { id: 3, nombre: "No Binario" },
        { id: 4, nombre: "Género Fluido" },
        { id: 5, nombre: "Agénero" },
        { id: 6, nombre: "DemiBoy" },
        { id: 7, nombre: "DemiGirl" },
        { id: 8, nombre: "Bigénero" },
        { id: 9, nombre: "Andrógino" },
        { id: 10, nombre: "Otro" },
    ],

    ventas: [
        { id: 1, usuario: "Juan Pérez", total: 1200, fecha: "2024-03-01", estado: "Completada" },
        { id: 2, usuario: "María Gómez", total: 800, fecha: "2024-03-02", estado: "Pendiente" },
        { id: 3, usuario: "Carlos Ruiz", total: 300, fecha: "2024-03-05", estado: "Cancelada" },
        { id: 4, usuario: "Ana López", total: 500, fecha: "2024-03-06", estado: "Completada" },
        { id: 5, usuario: "Pedro Fernández", total: 1500, fecha: "2024-03-07", estado: "Pendiente" },
        { id: 6, usuario: "Laura Martínez", total: 600, fecha: "2024-03-08", estado: "Cancelada" },
        { id: 7, usuario: "Diego Castro", total: 1100, fecha: "2024-03-09", estado: "Completada" },
        { id: 8, usuario: "Carla Ríos", total: 700, fecha: "2024-03-10", estado: "Pendiente" },
        { id: 9, usuario: "Jorge Ramírez", total: 1300, fecha: "2024-03-11", estado: "Completada" },
        { id: 10, usuario: "Mónica Torres", total: 400, fecha: "2024-03-12", estado: "Cancelada" }
    ],
    facturas: [
        { id: 201, numero: "F-0001", cliente: "Juan Pérez", monto: "$1500", fecha: "01/03/2024" },
        { id: 202, numero: "F-0002", cliente: "María Gómez", monto: "$3200", fecha: "02/03/2024" },
        { id: 203, numero: "F-0003", cliente: "Carlos López", monto: "$500", fecha: "05/03/2024" },
    ],
    metodosPago: [
        { id: 1, nombre: "Tarjeta de Crédito" },
        { id: 2, nombre: "Tarjeta de Débito" },
        { id: 3, nombre: "Transferencia Bancaria" },
        { id: 4, nombre: "MercadoPago" },
        { id: 5, nombre: "Efectivo" },
        { id: 6, nombre: "PayPal" },
        { id: 7, nombre: "Criptomonedas" },
        { id: 8, nombre: "Débito Automático" },
        { id: 9, nombre: "Pago en Cuotas" },
        { id: 10, nombre: "Saldo Virtual" }
    ],
    estadosVentas: [
        { id: 1, estado: "Pagado" },
        { id: 2, estado: "Pendiente" },
        { id: 3, estado: "Cancelado" },
    ],
    giftcards: [
        { id: 1, codigo: "GFT123", nombre: "Amazon", valor: 50, stock: 100 },
        { id: 2, codigo: "GFT456", nombre: "Steam", valor: 25, stock: 50 },
        { id: 3, codigo: "GFT789", nombre: "Netflix", valor: 30, stock: 20 },
        { id: 4, codigo: "GFT101", nombre: "Spotify", valor: 10, stock: 80 },
        { id: 5, codigo: "GFT202", nombre: "PlayStation", valor: 60, stock: 30 },
        { id: 6, codigo: "GFT303", nombre: "Xbox", valor: 40, stock: 25 },
        { id: 7, codigo: "GFT404", nombre: "Google Play", valor: 20, stock: 15 },
        { id: 8, codigo: "GFT505", nombre: "iTunes", valor: 35, stock: 10 },
        { id: 9, codigo: "GFT606", nombre: "Roblox", valor: 15, stock: 45 },
        { id: 10, codigo: "GFT707", nombre: "Nintendo", valor: 70, stock: 5 }
    ],
    categorias: [
        { id: 1, categoria: "Tecnología" },
        { id: 2, categoria: "Entretenimiento" },
        { id: 3, categoria: "Alimentación" },
    ],
    estadosGiftCards: [
        { id: 1, estado: "Disponible" },
        { id: 2, estado: "Agotado" },
        { id: 3, estado: "Descontinuado" },
    ],
    cupones: [
        { id: 1, codigo: "DESC10", descuento: "10%" },
        { id: 2, codigo: "DESC20", descuento: "20%" },
        { id: 3, codigo: "BLACKFRIDAY", descuento: "30%" },
        { id: 4, codigo: "CYBERMONDAY", descuento: "40%" },
        { id: 5, codigo: "NAVIDAD", descuento: "50%" },
        { id: 6, codigo: "VERANO", descuento: "15%" },
        { id: 7, codigo: "INVIERNO", descuento: "25%" },
        { id: 8, codigo: "SPRING", descuento: "35%" },
        { id: 9, codigo: "SUMMER", descuento: "45%" },
        { id: 10, codigo: "FREESHIPPING", descuento: "Envío Gratis" }
    ],
    paises: [
        { id: 1, nombre: "Argentina" },
        { id: 2, nombre: "Brasil" },
        { id: 3, nombre: "Chile" },
    ],
    provincias: [
        { id: 1, nombre: "Buenos Aires", pais: "Argentina" },
        { id: 2, nombre: "São Paulo", pais: "Brasil" },
        { id: 3, nombre: "Santiago", pais: "Chile" },
    ],
    localidades: [
        { id: 1, nombre: "La Plata", provincia: "Buenos Aires" },
        { id: 2, nombre: "Campinas", provincia: "São Paulo" },
        { id: 3, nombre: "Valparaíso", provincia: "Santiago" },
    ],
    direcciones: [
        { id: 1, calle: "Av. Siempre Viva", numero: "742", ciudad: "Springfield" },
        { id: 2, calle: "Calle Falsa", numero: "123", ciudad: "Madrid" },
    ],
    contactos: [
        { id: 1, nombre: "Juan Pérez", telefono: "+54 9 11 1234 5678", email: "juan@example.com" },
        { id: 2, nombre: "María Gómez", telefono: "+55 21 9876 5432", email: "maria@example.com" },
    ],
    configuracion: [
        { id: 1, opcion: "Habilitar modo oscuro", valor: "Sí" },
        { id: 2, opcion: "Notificaciones por email", valor: "No" },
        { id: 3, opcion: "Moneda predeterminada", valor: "USD" },
    ],
};

const DynamicABMPage: React.FC = () => {
    const location = useLocation();
    const section = location.pathname.split("/").pop() || "usuarios";
    const [items, setItems] = useState(dataSets[section] || []);
    const [formData, setFormData] = useState<any>({});
    const [editingId, setEditingId] = useState<number | null>(null);
    const [alert, setAlert] = useState<{ open: boolean; message: string; type: "success" | "error" | "warning" }>({
        open: false,
        message: "",
        type: "success",
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSave = () => {
        if (editingId !== null) {
            setItems(items.map((item) => (item.id === editingId ? { ...formData, id: editingId } : item)));
            setAlert({ open: true, message: "Registro actualizado", type: "success" });
        } else {
            setItems([...items, { ...formData, id: items.length + 1 }]);
            setAlert({ open: true, message: "Registro agregado", type: "success" });
        }
        resetForm();
    };

    const handleEdit = (item: any) => {
        setFormData(item);
        setEditingId(item.id);
    };

    const handleDelete = (id: number) => {
        setItems(items.filter((item) => item.id !== id));
        setAlert({ open: true, message: "Registro eliminado", type: "error" });
    };

    const resetForm = () => {
        setFormData({});
        setEditingId(null);
    };

    return (
        <Container>
            <CustomAlert open={alert.open} message={alert.message} type={alert.type} onClose={() => setAlert({ ...alert, open: false })} />
            <FormContainer>
                {Object.keys(items[0] || {}).map((key) =>
                    key !== "id" ? (
                        <Input key={key} name={key} placeholder={key} value={formData[key] || ""} onChange={handleChange} required />
                    ) : null
                )}
                <FormButton onClick={handleSave}>{editingId ? "Actualizar" : "Agregar"}</FormButton>
                {editingId && <FormButton onClick={resetForm} style={{ background: "#dc3545" }}>Cancelar</FormButton>}
            </FormContainer>
            <Table>
                <thead>
                    <TableRow>
                        {Object.keys(items[0] || {}).map((key) => (
                            <TableHeader key={key}>{key.toUpperCase()}</TableHeader>
                        ))}
                        <TableHeader>Acciones</TableHeader>
                    </TableRow>
                </thead>
                <tbody>
                    {items.map((item) => (
                        <TableRow key={item.id}>
                            {Object.entries(item).map(([key, value]) => (
                                <TableCell key={key}>{String(value)}</TableCell>
                            ))}
                            <TableCell>
                                <Tooltip title="Editar">
                                    <IconButton onClick={() => handleEdit(item)} color="primary">
                                        <Edit />
                                    </IconButton>

                                </Tooltip>
                                <Tooltip title="Eliminar">
                                    <IconButton onClick={() => handleDelete(item.id)} color="error">
                                        <Delete />
                                    </IconButton>
                                </Tooltip>

                            </TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default DynamicABMPage;
