import styled from "styled-components";

export const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const PaymentOption = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  background: ${({ selected }) => (selected ? "#007bff" : "#f8f9fa")};
  color: ${({ selected }) => (selected ? "#fff" : "#333")};
  border: 2px solid ${({ selected }) => (selected ? "#007bff" : "#ccc")};
  transition: 0.3s;

  &:hover {
    background: ${({ selected }) => (selected ? "#0056b3" : "#e2e6ea")};
  }
`;

export const PaymentLabel = styled.span`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
    margin-left: 15px;

`;

export const PaymentLogos = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 6px;
    margin-left: 20px;


  img {
    max-width: 45px;
    height: auto;
    object-fit: contain;
  }
`;

export const PaymentRadio = styled.input`
  margin-left: 10px;
  transform: scale(1.2);
`;

export const PaymentDetails = styled.div`
  margin-top: 10px;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 5px;

  label {
    font-size: 14px;
    font-weight: bold;
    display: block;
    margin-top: 5px;
  }

  input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  p {
    font-size: 14px;
    color: #333;
  }
`;

export const PaymentButton = styled.button`
  width: 100%;
  padding: 12px;
  background: #007bff;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background: #0056b3;
  }
`;