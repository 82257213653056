import styled from "styled-components";
import { Paper, Box } from "@mui/material";

export const CompraCard = styled(Paper)`
  display: flex;
  flex-direction: column;
  background: #f0faff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
  margin-left: 50px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
`;

export const InfoRow = styled.div`
    font-size: 16px;
    margin-bottom: 5px;
`;

export const Container = styled.div`
    display: flex;
    padding: 20px;
    gap: 20px;
`;

export const Content = styled.div`
    flex-grow: 1;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

export const CompraGroup = styled.div`
    margin-bottom: 25px;
`;

export const CompraItem = styled(Paper)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background:  #f0faff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    transition: 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
    }
`;

export const Fecha = styled(Paper)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f6e1c6;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
`;


export const ActionsContainer = styled.div`
    display: flex;
    gap: 10px;

    button {
        min-width: 130px;
    }
`;

export const ModalContainer = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #d1eaf6;
    padding: 25px;
    border-radius: 20px;
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 600px;  
    max-height: 80vh;  
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;


export const ModalContent = styled.div`
    text-align: left;
    overflow-y: auto;  
    max-height: 90vh;  
    padding-right: 15px; 

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #ff7e5f;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f0f0f0;
    }
`;


export const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
`;

export const DetailItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
`;

export const EstadoBadge = styled.span<{ estado: string }>`
    display: inline-block;
    padding: 5px 12px;
    border-radius: 8px;
    font-weight: bold;
    background: ${({ estado }) =>
    estado === "Pagado" ? "#28a745"
      : estado === "Pendiente" ? "#ffc107"
        : "#dc3545"};
    color: white;
`;

export const CanjeCode = styled(Paper)`
  background: #f6e1c6;
  padding: 10px;
  border-radius: 8px;
  margin: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  .code-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .code {
    font-weight: bold;
    font-size: 20px;
    color: #1a237e;
  }

  .expiry {
    font-size: 14px;
    color: #555;
  }

  .availability {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    margin-top: 6px;
  }

  .status-text {
    font-size: 14px;
    color: #333;
  }

  .status-icon {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .available {
    color: #28a745;
  }

  .expired {
    color: #d32f2f;
  }
`;

export const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 20px;
    color: #1a237e;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        color: red;
    }
`;
