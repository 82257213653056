import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import api from "../../Services/api";
import CustomText from "../../Components/CustomFields/CustomText/CustomText";
import CustomNumber from "../../Components/CustomFields/CustomNumber/CustomNumber";
import CustomSelect from "../../Components/CustomFields/CustomSelect/CustomSelect";
import CustomPhone from "../../Components/CustomFields/CustomPhone/CustomPhone";
import CustomEmail from "../../Components/CustomFields/CustomEmail/CustomEmail";
import CustomNotification from "../../Components/CustomNotification/CustomNotification";
import CustomModal from "../../Components/CustomModal/CustomModal";
import MainLayout from "../Layout/MainLayout";
import { useGeneros, useEstadosCiviles } from "../../Hooks/useConfigData";
import { FormGrid, ProfileContainer, SectionTitle } from "./style";
import { Banner, Button, Subtitle, Title } from "../Layout/style";
import { BannerContainer, Column } from "../Register/style";
import validateForm from "../Register/validateForm";
import { AxiosError } from "axios";
import { Contacto, Direccion } from "../../Interfaces/formInterfaces";
import { usePaises, useProvincias } from "../../Hooks/useConfigData";
import SidebarMenu from "./SidebarMenu";
import { Container } from "../MisCompras/style";


const UserEdit: React.FC = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState<"success" | "error">("success");

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState<string[]>([]);

    const [paisSeleccionado, setPaisSeleccionado] = useState<string>("");

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [provinciaSeleccionada, setProvinciaSeleccionada] = useState<string>("");

    const { generos } = useGeneros();
    const { estadosCiviles } = useEstadosCiviles();
    const { paises } = usePaises();
    const { provincias } = useProvincias(paisSeleccionado);


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await api.get("/api/usuario/me", {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                });
                setUser(response.data);

                // ✅ Preseleccionar valores al cargar el usuario
                if (response.data.direcciones.length > 0) {
                    const direccion = response.data.direcciones[0]; // Tomar la primera dirección
                    setPaisSeleccionado(direccion.pais);
                    setProvinciaSeleccionada(direccion.provincia);
                }
            } catch (err) {
                setError("Error al cargar los datos del usuario.");
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleUpdate = async (values: any) => {
        if (!user) return;

        const formData = new FormData();
        formData.append("UsuarioId", user.usuarioId.toString());
        formData.append("Nombre", values.nombre);
        formData.append("Apellido", values.apellido);
        formData.append("Email", values.email);
        formData.append("CUIL", values.cuil);
        formData.append("Genero", values.genero);
        formData.append("EstadoCivil", values.estadoCivil);

        values.direcciones.forEach((direccion: any, index: number) => {
            formData.append(`Direcciones[${index}].DireccionId`, direccion.direccionId?.toString() || "");
            formData.append(`Direcciones[${index}].Calle`, direccion.calle);
            formData.append(`Direcciones[${index}].Numero`, direccion.numero);
            formData.append(`Direcciones[${index}].CodigoPostal`, direccion.codigoPostal);
            formData.append(`Direcciones[${index}].Localidad`, direccion.localidad);
            formData.append(`Direcciones[${index}].Provincia`, direccion.provincia);
            formData.append(`Direcciones[${index}].Pais`, direccion.pais);
        });

        values.contactos.forEach((contacto: any, index: number) => {
            formData.append(`Contactos[${index}].ContactoId`, contacto.contactoId?.toString() || "");
            formData.append(`Contactos[${index}].CodigoPais`, contacto.codigoPais);
            formData.append(`Contactos[${index}].CodigoArea`, contacto.codigoArea);
            formData.append(`Contactos[${index}].Numero`, contacto.numero);
            formData.append(`Contactos[${index}].Tipo`, contacto.tipo);
        });

        try {
            await api.put(`/api/usuario/${user.usuarioId}`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            setAlertMessage("Datos actualizados exitosamente.");
            setAlertType("success");
            setOpenAlert(true);
            setTimeout(() => navigate("/perfil"), 2000);
        } catch (err) {
            const error = err as AxiosError<{ message?: string; validations?: { Code: string; Message: string }[] }>;

            if (error?.response?.data?.validations) {
                setModalMessage(error.response.data.validations.map(v => v.Message));
                setShowModal(true);
            } else {
                setModalMessage(["Error al actualizar los datos."]);
                setShowModal(true);
            }
        }
    };

    return (
        <MainLayout>
            <BannerContainer>
                <Banner>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img src="/img/giftwave_logo.png" alt="GiftWave Logo" style={{ width: "180px", marginRight: "20px" }} />
                        <div>
                            <Title>Editar Perfil</Title>
                            <Subtitle>Modifica tus datos personales</Subtitle>
                        </div>
                    </div>
                </Banner>
            </BannerContainer>

            <Container>

                <SidebarMenu />

                <ProfileContainer>
                    {loading ? (
                        <CircularProgress />
                    ) : error ? (
                        <Typography color="error">{error}</Typography>
                    ) : (
                        <Form onSubmit={handleUpdate} validate={validateForm} initialValues={user} render={({ handleSubmit, submitting }) => (
                            <form onSubmit={handleSubmit}>
                                <SectionTitle>Datos Personales</SectionTitle>
                                <FormGrid>
                                    <Column>
                                        <CustomText name="nombre" label="Nombre(s)" required />
                                        <CustomEmail name="email" label="Correo Electrónico" required />
                                        <CustomSelect
                                            name="generoId"
                                            label="Género"
                                            options={generos.map(g => ({ value: g.id, label: g.nombre }))}
                                            required
                                        />
                                    </Column>
                                    <Column>
                                        <CustomText name="apellido" label="Apellido(s)" required />
                                        <CustomText name="cuil" label="CUIL" required />
                                        <CustomSelect
                                            name="estadoCivilId"
                                            label="Estado Civil"
                                            options={estadosCiviles.map(e => ({ value: e.id, label: e.nombre }))}
                                            required
                                        />
                                    </Column>
                                </FormGrid>

                                {/* 📌 DIRECCIONES */}
                                <SectionTitle>Direcciones</SectionTitle>
                                {user?.direcciones?.length ? (
                                    user.direcciones.map((direccion: Direccion, index: number) => (
                                        <Box key={direccion.direccionId} sx={{ padding: 2, backgroundColor: "#f9f9f9", borderRadius: 2, marginBottom: 2 }}>
                                            <Typography variant="subtitle1" fontWeight="bold">
                                                Dirección {index + 1}
                                            </Typography>
                                            <Divider sx={{ marginBottom: "8px" }} />
                                            <FormGrid>
                                                <Column>
                                                    <CustomText name={`direcciones[${index}].calle`} label="Calle" required />
                                                    <CustomNumber name={`direcciones[${index}].numero`} label="Número" required />
                                                    <CustomNumber name={`direcciones[${index}].piso`} label="Piso" />
                                                </Column>
                                                <Column>
                                                    <CustomNumber name={`direcciones[${index}].codigoPostal`} label="Código Postal" required />
                                                    {provincias.length > 0 && (
                                                        <CustomSelect
                                                            name={`direcciones[${index}].provincia`}
                                                            label="Provincia"
                                                            options={provincias.map(p => ({ value: p.id, label: p.nombre }))}
                                                            required
                                                        />
                                                    )}
                                                    {paises.length > 0 && (
                                                        <CustomSelect
                                                            name={`direcciones[${index}].pais`}
                                                            label="País"
                                                            options={paises.map((p) => ({ value: p.id, label: p.nombre }))}
                                                            required
                                                            onChange={(value) => setPaisSeleccionado(value)}
                                                        />
                                                    )}
                                                </Column>
                                            </FormGrid>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography color="textSecondary">No hay direcciones registradas.</Typography>
                                )}

                                {/* 📌 CONTACTOS */}
                                <SectionTitle>Contactos</SectionTitle>
                                {user?.contactos?.length ? (
                                    user.contactos.map((contacto: Contacto, index: number) => (
                                        <Box key={contacto.contactoId} sx={{ padding: 2, backgroundColor: "#f9f9f9", borderRadius: 2, marginBottom: 2 }}>
                                            <Typography variant="subtitle1" fontWeight="bold">
                                                Contacto {index + 1}
                                            </Typography>
                                            <Divider sx={{ marginBottom: "8px" }} />
                                            <FormGrid>
                                                <Column>
                                                    <CustomSelect
                                                        name={`contactos[${index}].tipo`}
                                                        label="Tipo"
                                                        options={[
                                                            { value: "Celular", label: "Celular" },
                                                            { value: "Personal", label: "Personal" },
                                                            { value: "Trabajo", label: "Trabajo" },
                                                        ]}
                                                        required
                                                    />
                                                    <CustomPhone name={`contactos[${index}].codigoPais`} label="Código País" required />
                                                </Column>
                                                <Column>
                                                    <CustomText name={`contactos[${index}].codigoArea`} label="Código Área" required />
                                                    <CustomText name={`contactos[${index}].numero`} label="Número de Teléfono" required />
                                                </Column>
                                            </FormGrid>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography color="textSecondary">No hay contactos registrados.</Typography>
                                )}


                                {/* 📌 Botón Guardar */}
                                <Box textAlign="center" marginTop={3}>
                                    <Button align="center" type="submit" color="primary" disabled={submitting}>
                                        {submitting ? "Guardando..." : "Guardar Cambios"}
                                    </Button>
                                </Box>
                            </form>
                        )} />
                    )}

                    <CustomNotification open={openAlert} message={alertMessage} type={alertType} onClose={() => setOpenAlert(false)} />
                    <CustomModal open={showModal} type="error" title="Error" messages={modalMessage} onClose={() => setShowModal(false)} />
                </ProfileContainer>
            </Container>
        </MainLayout>
    );
};

export default UserEdit;
